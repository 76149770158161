.productFiltersToggleContainer {
    @media (max-width: 1021px) {
        display: none;
    }

    .productFiltersToggleInner {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 0 15px;
        height: 44px;
        width: 210px;
        background-color: #fff;
        border: 1px solid var(--product-filters-toggle-border-color, #000);
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 250ms ease-in-out;
        user-select: none;

        &:hover {
            border-color: var(--product-filters-toggle-hover-border-color, #000);
        }

        .toggleFiltersText {
            font-size: 14px;
            line-height: 1;
            text-transform: capitalize;
        }

        .filterCount {
            display: none;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.5;
            color: var(--product-filters-toggle-filter-count-color, #000);
            background-color: var(--product-filters-toggle-filter-count-background-color, #e7eaec);
            border-radius: 10px;

            .filtersHidden & {
                display: flex;
            }
        }
    }

    &.activeFilters {
        .productFiltersToggleInner {
            .filtersHidden & {
                border-color: var(--product-filters-toggle-active-border-color, #000);
            }

            .filterCount {
                min-width: 18px;
                padding: 0 4px;
            }
        }
    }
}
