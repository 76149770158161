.plp-product-swiper {
    overflow: hidden;
    position: relative;
}

.plp-product-swiper .swiper-button-next {
    right: 0;
}

.plp-product-swiper .swiper-button-prev {
    left: 0;
}

.plp-product-swiper .swiper-button-next,
.plp-product-swiper .swiper-button-prev {
    visibility: hidden;
    background-color: transparent;
    opacity: 1;
    transition: visibility 0.25s ease-in-out;
    background-image: none;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    margin-top: 0;
}

.plp-product-swiper .Browse .swiper-button-next,
.plp-product-swiper .Browse .swiper-button-prev {
    background-image: none;
}

.plp-product-swiper svg path {
    stroke-width: 1.5;
}

.plp-product-swiper svg:hover path {
    stroke: #000;
}

.plp-product-swiper:hover .swiper-button-next,
.plp-product-swiper:hover .swiper-button-prev {
    visibility: visible;
}
