.myIdShirtContainWrap #PersImage {
    max-width: 532px;
    margin: 0 auto;
    width: 100%;
}

.myIdPersonalisation .playerNameWrap,
.myIdPersonalisation .customNameWrap,
.myIdPersonalisation .numbersWrap {
    margin-bottom: 15px;
}

.myIdPersonalisation #divLetters,
.myIdPersonalisation #divListClubPlayer,
.myIdPersonalisation #divNumbers {
    padding: 0;
    width: 100%;
}

.myIdPersonalisation .personalisationnote,
.myIdPersonalisation .asterisck-xs,
.myIdPersonalisation .asterisck {
    padding: 0;
    font-size: 14px;
    font-style: normal;
    display: block;
}

.myIdPersonalisation .asterisck-xs,
.myIdPersonalisation .asterisck {
    padding-top: 5px;
    display: none;
}

.myIdPersonalisation .mobilePrintInfo {
    padding-bottom: 5px;
}

.myIdPersonalisation .mobilePrintInfo > span {
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
}

.myIdPersonalisation {
    .gothamDisclaimer,
    .old-radikal__disclaimer {
        display: none;
    }

    .GothamBold .gothamDisclaimer,
    .Radikal23 .old-radikal__disclaimer {
        display: block;
        color: #ed0000;
        font-size: 1.2em;
        padding-top: 10px;
    }
}

.myIdPersonalisation .getDetailsWrap {
    text-align: right;
}

.myIdPersonalisation .getDetailsWrap #aGetHelp {
    font-size: 1.2em;
    cursor: pointer;
    padding: 5px 0;
    margin: 5px 0;
    display: inline-block;
}

.myIdPersonalisation .MyIdbadgeText p {
    margin: 0;
    font-size: 14px;
}

.myIdPersonalisation .MyIdbadgeText p span {
    font-size: 14px;
    display: block;
}

.myIdPersonalisation .MyIdbadgeCheck {
    max-width: 44px;
}

.myIdPersonalisation .MyIdbadgeText {
    width: calc(100% - 44px);
}

.myIdPersonalisation .MyIdbadgeText label {
    margin-bottom: 0;
}

.myIdPersonalisation .MyIdbadgeText label,
.myIdPersonalisation .MyIdbadgeText span {
    text-align: left;
}

.myIdPersonalisation .PersHelpLink {
    display: none;
}

.myIdTabBody {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 15px;
    display: block;
    border: 1px solid #aaa;
}

#productDetails select,
#productDetails .letters input,
#productDetails .numbers input,
#productDetails .letnum input,
.HeroProdDet select {
    width: 100%;
    float: left;
    clear: none;
    font-size: 16px;
    border: 1px solid #ccc;
    height: 44px;
    padding: 7px 4px;
    margin-bottom: 10px;

    @media (min-width: 1022px) {
        font-size: 14px;
    }
}

#productDetails .myIdPersonalisation input,
#productDetails .myIdPersonalisation select {
    padding: 10px 20px;
    font-weight: bold;
    float: none;
    border-color: #999;
    text-transform: none;
    height: 48px;
    margin: 0;
}

#productDetails .myIdPersonalisation input:focus,
#productDetails .myIdPersonalisation select:focus {
    box-shadow: inset 0 0 3px 1px #0000ed;
}

#productDetails .letters input:disabled,
#productDetails .numbers input:disabled {
    background-color: #fff;
}

#productDetails select option,
.HeroProdDet select option {
    font-size: 0.91em;
    padding: 7px 4px;
    background-color: #fff;
}

.infoTabPageContainer {
    text-align: center;
    padding: 10px 10px 0;
}

.infoTabs,
.myIdTabs {
    display: block;
}

.myIdPersonalisationWrap {
    padding-bottom: 10px;
}

.RadTabStrip {
    padding: 0;
    margin: 0;
    min-height: 0;
}

@media (min-width: 768px) and (max-width: 1021px) {
    #divPersonalisation.productPersonalisation .letnum input,
    #divPersonalisation.productPersonalisation .listthc select,
    #divPersonalisation.productPersonalisation .letters input,
    #divPersonalisation.productPersonalisation .numbers input,
    #divPersonalisation.productPersonalisation .listclubplayer select,
    #divPersonalisation.productPersonalisation .trophy input,
    #divPersonalisation.productPersonalisation .flagembgroup select,
    .dropdownPers .divPersonalisationDropdown button {
        padding: 10px 20px;
    }
}

@media (min-width: 768px) {
    .RadTabStrip {
        margin: 0;
    }
}

@media (max-width: 1021px) {
    .myIdTabs .RadTabStrip .rtsLevel .rtsIn {
        font-size: 1em;
    }
}

.myIdShirtContainWrap {
    margin: 0 0 15px;
}

.myIdPersonalisationContainer .myIdTabs .infoTabPageContainer {
    padding: 20px 15px 0;
    text-align: left;
}

.RadTabStrip .rtsLevel .rtsOut:hover,
.RadTabStrip .rtsLevel .rtsIn:hover,
.RadTabStrip .rtsLevel .rtsTxt:hover {
    color: #444 !important;
}

.RadTabStrip .rtsLevel .rtsOut,
.RadTabStrip .rtsLevel .rtsIn,
.RadTabStrip .rtsLevel .rtsTxt {
    color: #888 !important;
    height: 100%;
}

.RadTabStrip .rtsLevel .rtsSelected .rtsIn {
    color: #444 !important;
    background-color: #fff !important;
    border-bottom: 1px solid #fff;
}

.RadTabStrip.RadTabStrip_Vista_Dark .rtsLevel .myIdRtsSelected .rtsIn {
    color: #fff !important;
    background-color: #000 !important;
    text-align: center !important;
    padding: 15px 25px;
}

.RadTabStrip .rtsIn,
.RadTabStripVertical .rtsIn {
    background-color: #eee;
    border-bottom: 0;
}

.RadTabStrip.RadTabStrip_Vista_Dark .rtsIn,
.RadTabStripVertical .rtsIn {
    background-color: #fff;
    text-align: center !important;
    text-transform: capitalize;
    border: 1px solid #aaa;
    padding: 15px 25px !important;
}

.myIdPersonalisationContainer .myIdTabs .multiPage {
    height: auto;
    border: 0;
}

.myIdPersonalisationContainer .ImgButWrap .ContinueWithPers {
    padding: 20px 5px;
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
    display: block;
    margin-top: 10px;
}

.RadTabStrip::after {
    display: block;
    height: 0;
    clear: both;
    content: "";
}

.RadTabStrip.RadTabStrip_Vista_Dark .rtsLink {
    padding-left: 0 !important;
    text-align: center;
    display: block;
    cursor: pointer;
    text-decoration: none;
    height: 100%;
}

.RadTabStrip .rtsLevel {
    position: static;
    width: 100%;
    clear: both;
    padding-top: 0;
    overflow: hidden;
    min-height: 0;
}

.RadTabStrip .rtsUL {
    list-style-type: none;
    margin: 0;
    min-height: 0;
}

.RadTabStrip .rtsLI {
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    min-height: 0;
    zoom: 1;
    float: left;
}

.myIdTabs-2tabs .rtsLI {
    width: 50%;
    height: 50px;
}

.RadTabStrip .rtsIn {
    padding: 0;
    display: block;
}

.RadTabStrip .rtsLevel .rtsIn {
    font-size: 1.2em;
    letter-spacing: 0.05em;
    font-weight: bold;
    line-height: 1.4em;
    text-transform: uppercase;
    padding: 15px 5px !important;
    transition: background-color ease-in-out 500ms;
}

.RadTabStrip .rtsLevel .rtsIn:hover {
    background-color: #eee;
}

#prodcutDetails .pTypeMininumMyIdTitle {
    margin-bottom: 0;
    font-size: 1.1em;
}

#divMininum {
    padding: 0 10px 5px;
}

.divCheckItem {
    padding: 0 10px 5px;
}

.badgesWrap > .divCheckItem {
    padding: 0;
}

#helpHtmlContainer {
    top: 0;
}

#helpTitleHtml {
    text-transform: uppercase;
}

.help-html-close {
    opacity: 1 !important;
    text-indent: -9999px;
    margin: 0;
    position: absolute;
    right: 18px;
    background: url("/images/core/closedbutton.png") no-repeat center center #333 !important;
    z-index: 1;
    background-image: url("/images/core/sd-new-main-sprite-15-v12.svg") !important;
    width: 29px;
    height: 29px;
    background-color: transparent !important;
    background-size: 700px 700px !important;
    background-position: -265px -491px !important;
    border: 0;
    transition: none;
}

#helpTitleHtml button:hover {
    background-color: #f5f5f5 !important;
}

@media (min-width: 768px) {
    .popupPersonalisation .myIdShirtNumber label {
        font-size: 14.2em !important;
    }

    .popupPersonalisation .myIdShirtName label {
        font-size: 2.05em !important;
    }

    .popupPersonalisation .England2020 .myIdShirtName label {
        font-size: 3.3em !important;
    }

    .popupPersonalisation .England2020Num {
        transform: scale(0.95);
    }
}
