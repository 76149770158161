.layout li a,
.SelectableFilter,
.SelectedFilter,
#ProductContainer input[type="button"].QuickWishListButton,
.QuickBuyAndWishListContainerDiv span.PLWishBefore {
    background: url("/images/core/PLCLv3.png") no-repeat;
}

.productFilterList ul,
.productFilterList ul li a {
    margin: 0;
    padding: 0;
}

.productFilterList ul {
    max-height: 257px;
}

.productFilterListV3 ul,
.productFilterListV3 ul li a {
    margin: 0;
    padding: 0;
}

.productFilterListV3 ul {
    max-height: 257px;
}

.search-para {
    float: left;
    font-size: 14px;
    padding: 0 10px 0 16px;
    width: 86%;
}

.searchHeader {
    clear: right;
    float: left;
    padding: 4px 10px 0;
    width: 87%;
}

#searchresultsimage {
    float: left;
    padding: 2px 12px;
    width: 60px;
}

.Browse .s-maincontent-container {
    padding: 0;
}

.s-productscontainer2 > li {
    padding-left: 2px;
    padding-right: 2px;
    width: 20%;
    margin-bottom: 5px;
    vertical-align: top;
    display: inline-block;
    margin-right: -3px;
}

.AltProdList .s-productscontainer2 > li {
    padding-left: 5px;
    padding-right: 5px;
}

.sdlProdList .s-productscontainer2 {
    margin: 0 -5px;
    width: auto;
}

.sdlProdList .s-productscontainer2 > li {
    margin-bottom: 10px;
}

.columns4 .s-productscontainer2 > li {
    width: 25%;
}

.columns3 .s-productscontainer2 > li {
    width: 33.333333%;
}

.Browse h1 {
    width: 100%;
    float: none;
    font-size: 1.4em;
    padding: 0 0 10px;
    display: inline-block !important;
}

.Browse .HeadnCount h1 {
    width: auto;
    vertical-align: top;
    padding: 0;
    letter-spacing: 0.1em;
}

.Browse .categoryListSide {
    margin: 0;
    width: 0;
    padding: 0;
}

.categoryListSide ul,
.categoryListSide ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.Browse .categoryListSide li {
    list-style-type: none;
    padding-left: 14px;
}

.Browse .categoryListSide li:hover {
    text-decoration: underline;
}

.Browse .categoryListSide a {
    display: block;
    font-size: 1em;
    margin: 0 0 8px 2px;
    padding: 0;
}

.Browse .products .products {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 682px;
}

.Browse .products .product {
    float: left;
    width: 136px;
    height: 24em;
    margin-right: 0;
}

.Browse .products .product li {
    text-align: center;
}

.Browse .products .product .productimage {
    padding-top: 6px;
}

.Browse .products .product .productbrand {
    font-size: 1.3em;
    font-weight: bold;
    padding-left: 3px;
    padding-right: 3px;
}

.Browse .products .product .productdescription a {
    height: 3.5em;
    padding-left: 3px;
    padding-right: 3px;
}

.Browse .products .product .recommendedprice {
    text-decoration: line-through;
    font-size: 1.4em;
    height: 1.3em;
    padding-top: 2px;
}

.Browse .products .product .sellingprice {
    font-size: 1.8em;
    font-weight: bold;
}

.Browse .products .product .attributes {
    font-size: 1.1em;
    padding: 0 6px 5px;
}

.Browse .sortbyfilter span {
    font-weight: bold;
}

.Browse #ctl00_MainContent_GenderFilterText {
    font-size: 1.3em;
    font-weight: bold;
    display: block;
    margin: 0 24px 0 17px;
}

.Browse .productcategories li {
    float: left;
    width: 215px;
    margin: 0 4px 0 5px;
    height: 155px;
    text-align: center;
}

.Browse .productcategories li img {
    width: 215px;
    height: 145px;
}

.Browse .productcategories li a {
    text-decoration: none;
    width: 215px;
    text-align: center;
}

.catheaderimage img {
    width: 100%;
    float: left;
    margin-bottom: 5px;
}

.Browse .categorycopy {
    margin: 0 0 12px;
    padding: 8px 18px;
    font-size: 1.3em;
}

.Browse .s-containercontent {
    width: 838px;
    margin-bottom: 10px;
}

.Browse .recentitems .recommendedprice {
    display: none;
}

.topheadbox {
    min-height: 20px;
    width: 100%;
}

.ImgTxtContainer {
    text-align: center;
}

.Browse .ImgTxtContainer .online-prices-differ-note {
    margin-bottom: 10px;
    font-size: 11px;
    letter-spacing: 0.05em;
    color: #888;
}

.categorycopyd4 {
    display: inline-block;
    padding: 0 0 10px;
    width: 100%;
    font-size: 14px;
}

.categorycopyd4 img {
    max-width: 100%;
    margin-bottom: 5px;
    height: auto;
}

#productList {
    width: 838px;
    height: auto;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.s-productscontainer {
    padding: 0;
    float: left;
    height: auto;
    width: 662px;
    margin: 0 0 10px;
}

.s-categorycontainer {
    float: left;
    height: auto;
    padding: 0;
    margin: 4px 0 0;
    width: 838px;
}

.s-productcategorybox {
    margin: 0 5px 10px;
    padding: 0;
    float: left;
    display: inline;
    height: auto;
    width: 177px;
}

.s-productcategorybox2 {
    margin: 0;
    padding: 0;
    float: left;
    height: auto;
    width: 158px;
}

.s-productthumbbox {
    padding: 0 0 10px;
    display: block;
    border: 1px solid #eee;
    height: 100%;
    width: 100%;
    position: relative;
}

.s-productthumbbox h4 {
    font-size: 1em;
    padding-bottom: 0;
    line-height: normal;
}

.AltProdList .s-productthumbbox h4 {
    text-align: center;
}

.s-productthumbbox h4 a {
    display: inline-block;
}

.AltProdList .s-productthumbbox {
    border: none;
}

.sdlProdList .s-productthumbbox {
    border: 1px solid transparent;
    padding: 10px 5px;
    overflow: hidden;
}

.sdlProdList .s-productthumbbox:hover {
    border: 1px solid #ddd;
}

.s-productthumbbox .s-product-sache {
    top: 0;
    right: 5px;
    z-index: 950 !important;
    max-width: 130px;
}

.s-productthumbbox .s-productthumbimage {
    float: left;
    text-align: center;
    position: relative;
}

.s-productthumbbox .s-productthumbimage .ProductImageList {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.s-productthumbbox .s-productthumbimage .ProductImageList .AlternateImageContainerDiv {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.s-productthumbbox .s-productthumbimage .ProductImageList img {
    display: inline-block;
    margin: 0 auto;
    aspect-ratio: 1 / 1;
}

.s-productthumbtext {
    float: left;
    width: 100%;
}

.s-producttext-top-wrapper {
    overflow: hidden;
    padding: 4px 4px 2px;
    line-height: 1.2em;
}

.columns4 .s-producttext-top-wrapper {
    padding: 6px 8px 2px;
}

.columns3 .s-producttext-top-wrapper {
    padding: 6px 10px 2px;
}

.s-producttext-top-wrapper > a {
    display: block;
}

.s-producttext-top-wrapper a:hover {
    text-decoration: underline;
}

.s-productthumbbox .s-producttext-price {
    overflow: hidden;
    padding: 4px 4px 0;
    width: 100%;
}

.columns4 .s-producttext-price {
    padding: 4px 8px 0;
}

.columns3 .s-producttext-price {
    padding: 4px 10px 0;
}

.rollUpQuickBuyWrap {
    width: 100%;
    float: left;
}

.s-productthumb-extra {
    font-size: 0.9em;
    line-height: 1.5em;
    overflow: hidden;
    padding: 5px 0 0;
    float: left;
    width: 100%;
}

.s-productthumb-extra .sizeText {
    font-weight: bold;
    padding: 0 2px 0 0;
}

.sdlProdList .s-productthumb-extra .sizeText {
    color: #777;
}

.s-productthumbbox .s-productthumb-extra .s-productsize {
    padding: 0 4px;
    display: block;
}

.columns4 .s-productthumb-extra .s-productsize {
    padding: 0 8px;
}

.columns3 .s-productthumb-extra .s-productsize {
    padding: 0 10px;
}

.s-productthumbbox2 {
    margin: 0;
    padding: 0;
    float: left;
    height: auto;
    width: 130px;
}

.s-productscontainer2 {
    width: 100%;
    margin: 0 0 8px;
}

.s-filters {
    margin: 0;
    padding: 10px;
    float: left;
    height: 38px;
    width: 662px;
}

.s-filters h2 {
    text-transform: uppercase;
    font-size: 1em;
}

.s-filters a {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
}

.productFilters {
    margin: 0;
}

.productFiltersReset {
    float: right;
}

.productFiltersReset a {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 3px 6px;
    display: inline-block;
    cursor: pointer;
    font-size: 1.1em;
    border: 1px solid #ddd;
}

ul.productFilters > li {
    position: relative;
}

ul.productFilters > li::after {
    content: " ";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}

.productFilterTitleBox,
.productFilterTitleBoxCLOSED {
    cursor: pointer;
    padding: 4px 0;
    user-select: none;
}

.productFilterTitleBox.non-collapsible {
    cursor: default;
}

.productFilterTitleBox img {
    float: right;
    padding-top: 1px;
}

.mobSortDesc {
    width: calc(100% - 26px);
    display: inline-block;
}

.productFilterTitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.3em;
    padding: 0;
}

.productFilterTitleBox a.productFilterClear {
    display: none;
}

.productFilterClear {
    float: right;
    font-size: 1em !important;
    padding-right: 2px;
    padding-top: 0;
    text-transform: uppercase;
}

.productFilterList .FilterAnchor {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 6px 0;
}

.productFilterList .SortQtyName a {
    width: auto;
    cursor: pointer;
}

.Responsive .FilterListItem a:hover .FilterName {
    text-decoration: underline;
}

.paginationWrapper .PrevNextButtons {
    margin: 6px 0;
}

.paginationWrapper .PrevNextButtons.PageNumber .NextLink,
.paginationWrapper .PrevNextButtons.PageNumber .PrevLink {
    background: none;
    text-indent: 0;
    border: none;
    padding: 0 10px;
}

.paginationWrapper .PrevNextButtons.PageNumber .NextLink.DisabledLink,
.paginationWrapper .PrevNextButtons.PageNumber .PrevLink.DisabledLink {
    color: #ccc;
    pointer-events: none;
}

.paginationWrapper .PrevNextButtons.PageNumber .NextLink:hover,
.paginationWrapper .PrevNextButtons.PageNumber .PrevLink:hover {
    text-decoration: none;
}

.paginationWrapper .PrevNextButtons.PageNumber .PrevLink {
    border-right: 1px solid #000;
}

.paginationWrapper .PrevNextButtons.PageNumber .NextLink {
    border-left: 1px solid #000;
}

.paginationWrapper .PrevNextButtons.PageNumber .NextLink,
.paginationWrapper .PrevNextButtons.PageNumber .PrevLink,
.paginationWrapper .PrevNextButtons.PageNumber .CurrentPageNumber,
.paginationWrapper .PrevNextButtons.PageNumber .PageSeperator,
.paginationWrapper .PrevNextButtons.PageNumber .MaxPageNumber {
    text-transform: uppercase;
    line-height: 16px;
    height: auto;
    letter-spacing: 0.1em;
    vertical-align: middle;
}

.paginationWrapper .PrevNextButtons.PageNumber .CurrentPageNumber {
    padding: 0 5px 0 10px;
}

.paginationWrapper .PrevNextButtons.PageNumber .MaxPageNumber {
    padding: 0 10px 0 5px;
}

.productFilterList {
    padding: 5px 0 0;
    min-height: 22px;
    border-top: 1px solid #ddd;
}

.productFilterListV3 a {
    cursor: pointer;
    line-height: 1.4em;
    display: block;
}

.FilterListItem {
    min-height: 25px;
    width: 100%;
}

.productFilterListMany {
    overflow: auto;
}

.NotSelectableFilter {
    padding: 0 0 0 18px;
    width: auto;
    line-height: 1.2em;
}

.SelectableFilter,
.SelectedFilter {
    padding: 0 0 0 20px;
    display: block;
    font-size: 1em;
    background-position: -94px -112px;
    background-color: transparent;
    width: 100%;
}

.SelectableFilter:hover {
    background-position: -112px -95px;
}

.SelectedFilter {
    background-position: -129px -78px;
}

.SelectedFilter:hover {
    text-decoration: none;
    background-position: -146px -60px;
}

.productFilterList .FilterAnchor > span::before {
    content: " ";
    display: table;
}

.productFilterList .FilterAnchor > span::after {
    content: " ";
    display: table;
    clear: both;
}

.MoreRowsOnFilter,
.LessRowsOnFilter {
    font-style: italic;
    font-weight: bold;
    padding-left: 16px;
}

.MoreRowsOnFilter:hover,
.LessRowsOnFilter:hover {
    text-decoration: underline;
}

.categoryListSide h2 {
    margin-bottom: 8px;
    font-size: 1.2em;
    padding: 0 4px 4px 0;
}

.extraCategoryInfo {
    clear: both;
    font-size: 1.1em;
    height: auto;
    margin: 0;
    padding: 0;
    text-align: justify;
    width: auto;
    float: left;
}

.noprodos {
    height: auto;
    display: inline-block;
    float: left;
}

.noprodos p {
    font-weight: bold;
    font-size: 1em;
    height: auto;
    padding: 0;
}

.QuickBuyWishListButtonContainer {
    bottom: 6px;
    position: absolute;
    width: 35px;
    height: 35px;
    text-align: center;
    right: 0;
    z-index: 975 !important;
}

input.QuickBuyButton,
input.QuickWishListButton {
    height: 22px;
    padding: 0 6px;
    width: 100%;
}

#ProductContainer input[type="button"].QuickWishListButton {
    background-color: transparent !important;
    color: #fff !important;
    text-indent: -999999px;
    height: 35px;
    width: 35px;
    background-position: -124px -131px;
    transition: none;
}

#ProductContainer input[type="button"].QuickWishListButton:hover {
    background-position: -124px -173px;
}

.QuickBuyAndWishAdditionalContainer .QuickBuyWishListButtonContainer {
    width: 50%;
}

.QuickBuyButtonProductListContainerNoWishList {
    width: 100%;
}

.QuickBuyAndWishListContainerDiv span.PLWishBefore {
    background-position: -132px -130px;
    background-color: transparent;
    height: 35px;
    width: 35px;
    display: block;
}

.GroupContainer {
    float: right;
    left: auto;
    margin-bottom: 10px;
    position: relative;
    right: 10px;
    top: 0;
}

.pagination .PageNumber {
    float: right;
    text-align: right;
}

.pagination .PageNumber.PageNumberCenter {
    float: none;
    text-align: center;
}

.pagination .PageNumber .PageNumberInner {
    display: inline-block;
    width: 100%;
}

.crusProdList .pagination .PageNumber {
    margin-left: 30px;
}

.PageNumber a,
.PageNumber span.PageSelector {
    display: inline-block;
    font-size: 1.2em;
    line-height: 35px;
    margin: 0 0.5%;
    padding: 0 5px;
    height: 35px;
    min-width: 35px;
    text-align: center;
    vertical-align: middle;
}

.PageNumber a.NextLink,
.PageNumber a.PrevLink {
    text-indent: -9999px;
    width: 35px;
    border: 1px solid #ddd;
    font-size: 11px;
    padding: 0;
    margin: 0;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 300px 300px;
    background-position: -217px -15px;
}

.PageNumber a.NextLink:hover {
    background-position: -240px -15px;
    border: 1px solid #aaa;
}

.PageNumber a.PrevLink {
    background-position: -217px -48px;
}

.PageNumber a.PrevLink:hover {
    background-position: -240px -48px;
    border: 1px solid #aaa;
}

.PageNumber a:hover {
    text-decoration: underline;
}

.s-maincontent-container .pagination {
    display: block;
    border-radius: 0;
    margin: 0 -5px;
}

.pagination .sortbyfilter,
.paginationTop .sortbyfilter {
    border-right: 1px solid #ddd;
    float: left;
    height: 100%;
    text-align: center;
}

.pagination .sortbyfilter label,
.paginationTop .sortbyfilter label {
    display: inline-block;
    font-size: 14px;
    margin-right: 5px;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 35px;
    vertical-align: middle;
}

.dropprods_Order {
    display: inline-block;
    font-size: 14px;
    padding: 4px;
    height: 35px;
    width: 70%;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #fff;
}

.dropprods_Order:hover,
.dropprods_Order:focus {
    border: 1px solid #aaa;
}

.pagination .PageSelector {
    background-color: #eee;
    font-weight: bold;
}

a.pppl {
    display: inline-block;
    font-weight: bold;
    height: 100%;
    line-height: 2em;
}

.pppt {
    display: inline-block;
    height: auto;
    line-height: 2.1em;
    vertical-align: text-top;
}

.HeadnCount .pppt {
    line-height: normal;
    font-style: italic;
    letter-spacing: 0.1em;
}

.pppt span {
    font-size: 1.6em;
}

.HeadnCount .pppt span {
    font-size: 1.2em;
}

.HeadnCount .pppt::before {
    content: "(";
    font-size: 1.2em;
}

.HeadnCount .pppt::after {
    content: ")";
    font-size: 1.2em;
}

.PageFromTo {
    border-right: 1px solid #ddd;
    float: left;
    height: 35px;
    padding-top: 7px;
}

.HeadnCount .PageFromTo {
    padding: 0 0 0 5px;
    display: inline-block;
    float: none;
    height: auto;
    border: 0;
    visibility: visible;
    vertical-align: middle;
}

.layout {
    border-right: 1px solid #ddd;
    text-align: center;
    line-height: 1.2em;
    max-height: 35px;
}

.layout .LayoutGroup {
    display: inline-block;
    max-height: 35px;
    zoom: 1;
}

.layout span.ViewbyCols {
    color: #333;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
    padding: 0 5px 0 0;
    line-height: 35px;
}

.layout ul,
.layout li {
    max-height: 35px;
}

.layout ul {
    display: inline-block;
    vertical-align: middle;
}

.layout li {
    text-align: center;
    padding: 3px;
    border: 1px solid #ccc;
    margin-right: 10px;
    float: left;
    cursor: pointer;
}

.layout li:hover {
    border: 1px solid #aaa;
}

.layout li a {
    display: inline-block;
    height: 28px;
    width: 28px;
}

.layout li.coloptmob1 a {
    background-position: 0 -2px;
}

.layout li.coloptmob1 a:hover,
.layout li.coloptmob1.selected a {
    background-position: 0 -27px;
}

.layout li.coloptmob2 a {
    background-position: -34px -2px;
}

.layout li.coloptmob2 a:hover,
.layout li.coloptmob2.selected a {
    background-position: -34px -27px;
}

.layout li.colopt3 a {
    background-position: -67px -2px;
}

.layout li.colopt3 a:hover,
.layout li.colopt3.selected a {
    background-position: -67px -27px;
}

.layout li.colopt4 a {
    background-position: -102px -2px;
}

.layout li.colopt4 a:hover,
.layout li.colopt4.selected a {
    background-position: -102px -27px;
}

.layout li.colopt5 a {
    background-position: -136px -2px;
}

.layout li.colopt5 a:hover,
.layout li.colopt5.selected a {
    background-position: -136px -27px;
}

.ViewPerPage {
    border-right: 1px solid #ddd;
    float: left;
    height: 100%;
    text-align: center;
    font-size: 1.1em;
}

.s-largered {
    display: inline-block;
    vertical-align: top;
}

.s-largered span {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1em;
}

.AltProdList .s-largered span {
    font-weight: normal;
}

.s-producttext-withticket .s-largered {
    padding-right: 3px;
}

.sdlProdList .HeadnCount h1 {
    text-transform: uppercase;
    font-size: 1.5em;
}

.sdlProdList .HeadnCount .pppt {
    font-size: 1.2em;
    line-height: 1em;
}

.sdlProdList .categorycopyd4,
.sdlProdList .HeadnCount .pppt span {
    font-size: 1em;
}

.sdlProdList .s-producttext-price .s-largered {
    display: block;
    padding-bottom: 2px;
}

.sdlProdList .s-producttext-price .labelplusTicket {
    display: inline-block;
}

.sdlProdList .s-largered span {
    font-weight: 700;
}

.columns4 .s-largered span {
    font-size: 1.8em;
}

.columns3 .s-largered span {
    font-size: 2.1em;
}

.RefandPrice {
    display: inline-block;
    vertical-align: top;
    line-height: 1.32em;
}

.RefandPrice .s-smalltext {
    display: inline-block;
    vertical-align: top;
    text-decoration: line-through;
}

.RefandPrice span {
    font-size: 1.1em;
    line-height: 1.1em;
}

.columns4 .RefandPrice span {
    font-size: 1.2em;
}

.columns3 .RefandPrice span {
    font-size: 1.3em;
}

.columns5 .RefandPrice span.CurrencySizeSmall {
    font-size: 0.8em;
}

.columns4 .RefandPrice span.CurrencySizeSmall {
    font-size: 0.9em;
}

.columns3 .RefandPrice span.CurrencySizeSmall {
    font-size: 1em;
}

.columns5 .RefandPrice span.CurrencySizeMedium {
    font-size: 0.8em;
}

.columns4 .RefandPrice span.CurrencySizeMedium {
    font-size: 1em;
}

.columns3 .RefandPrice span.CurrencySizeMedium {
    font-size: 1.1em;
}

.s-producttext-price .AdditonalPriceLabel {
    display: inline-block;
    vertical-align: top;
    color: #666;
    line-height: 1.32em;
}

.s-producttext-withticket .AdditonalPriceLabel {
    padding-right: 2px;
}

.s-producttext-price span.ticketPricePrefix {
    font-size: 0.9em;
}

.s-producttext-FRAS {
    color: #260e5d;
    font-weight: Bold;
    text-transform: uppercase;
}

.s-producttext-STUD {
    color: #260e5d;
    font-weight: Bold;
    text-transform: uppercase;
}

.clearfilters {
    display: none;
}

.CopyContainRunning,
.CopyContainCricket,
.CopyContainGolf {
    display: inline-block;
    margin-top: 6px;
}

.CopyBackG {
    width: 100%;
    min-height: 110px;
    margin-bottom: 6px;
    float: left;
}

.CopyContainRunning .CopyBackG {
    background: url("/images/marketing/running-header.png") no-repeat scroll 0 0 #ab162b;
}

.CopyContainCricket .CopyBackG {
    background: url("/images/marketing/cricket-header.png") no-repeat scroll 0 0 #404041;
}

.CopyContainGolf .CopyBackG {
    background: url("/images/marketing/golf-header.png") no-repeat scroll 0 0 #231f20;
}

.CopyBackG span {
    color: #fff;
    float: right;
    font-size: 1.1em;
    line-height: 1.4em;
    padding: 15px 15px 10px 0;
    text-align: justify;
    width: 65%;
}

.CopyBackG span a {
    color: #fff;
}

.CopyBackG span a:hover {
    color: #fff;
    text-decoration: underline;
}

.CopyBoxGroups {
    width: 100%;
    margin-bottom: 6px;
    float: left;
}

.CopyBoxGroups span {
    float: left;
    min-height: 16px;
    text-align: center;
    width: 25%;
}

.CopyBoxGroups span a {
    text-decoration: underline;
}

.CopyBoxGroups span a:hover {
    text-decoration: none;
}

.HorzComplete,
.HorzPartial,
.BestSellerCat {
    float: right;
}

.s-leftcolumn {
    float: left;
}

.s-rightcolumn {
    margin: 0;
    padding: 0;
    float: right;
    height: auto;
}

.Responsive .s-largered span.fromProductPrice {
    font-size: 0.8em;
    padding-right: 1px;
}

#divsiblinglistwrapper {
    width: 100%;
    padding: 5px;
}

#divsiblinglistwrapper ul {
    text-align: center;
}

#divsiblinglistwrapper ul.ulFirst {
    display: block;
}

#divsiblinglistwrapper #divViewMore {
    display: inline-block;
}

#divsiblinglistwrapper ul li {
    margin: 0;
    display: inline-block;
    height: 20px;
    width: auto;
    overflow: hidden;
}

#FilterContainer #divsiblinglistwrapper ul li {
    width: 100%;
}

#divsiblinglistwrapper ul li a {
    padding: 4px 10px;
    display: inline-block;
    font-size: 1.1em;
}

#FilterContainer #divsiblinglistwrapper ul li a {
    padding: 2px 0;
}

#divsiblinglistwrapper ul li a:hover {
    text-decoration: underline;
}

.productFilterList .FilterName {
    float: left;
    width: 75%;
    font-size: 14px;
}

.productFilterList .FilterValue {
    float: right;
    width: 25%;
    text-align: right;
    font-size: 14px;
}

.productFilterListV3 .FilterName {
    float: left;
    width: 73%;
}

.productFilterListV3 .FilterValue {
    float: right;
}

.SelectedFiltersContainer.mobileSelectedFilters {
    display: none;
}

.SelectedFiltersLabel {
    display: none;
}

#scroll-pane,
.scroll-pane {
    position: relative;
    max-height: 176px;
    min-height: 20px;
}

.scroll-content {
    position: inherit;
}

.scroll-pane {
    overflow: hidden;
}

.slider-wrap {
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
}

.slider-vertical {
    position: relative;
    height: 100%;
}

.ui-slider-handle {
    width: 0.4em !important;
    height: 10px;
    margin: 0 auto;
    background-color: #a9a9a9;
    display: block;
    position: absolute;
}

.ui-slider-handle img {
    border: none;
}

.scrollbar-top {
    position: absolute;
    top: 0;
}

.scrollbar-bottom {
    position: absolute;
    bottom: 0;
}

.scrollbar-grip {
    position: absolute;
    top: 50%;
    margin-top: -6px;
}

.ui-slider-range {
    position: absolute;
    width: 100%;
}

.slider-wrap .ui-slider {
    background-color: transparent;
}

.FilterListItem a.greyOut {
    opacity: 0.7;
}

.FilterListItem__show {
    display: block;
}

.FilterListItem__hide {
    display: none;
}

.siblingcategory {
    margin-bottom: 10px;
}

.siblingcategory #divViewMore span {
    display: inline-block;
    color: #000;
    padding: 6px 0 0 12px;
    cursor: pointer;
    font-size: 1.2em;
    background: url("/images/core/arrow_down.gif") no-repeat scroll -9px 14px transparent;
}

.siblingcategory #divViewMore.hideCat span {
    background: url("/images/core/arrow_right.gif") no-repeat scroll 0 13px transparent;
}

#divViewMore span:hover {
    text-decoration: underline;
}

.mobCbRow {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 6000 !important;
    display: none;
    height: 56px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}

#mobControlBar {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
}

#mobSortFilter {
    height: 100%;
}

#filterByMob,
#sortByMob {
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    transform: translateY(-50%);
}

#mobControlBar .layout {
    border: none;
}

#mobControlBar .layout ul {
    border: #ccc 1px solid;
    background: #fff;
    width: 100%;
    height: 44px;
}

#mobControlBar .layout .LayoutGroup {
    width: 100%;
    max-height: 44px;
}

#mobControlBar .layout li {
    float: none;
    border: none;
    display: inline-block;
    margin-right: 0;
    padding-top: 6.5px;
    width: 25%;
}

#mobControlBar .layout,
#mobControlBar .layout ul,
#mobControlBar .layout li {
    max-height: 44px;
}

.MobInstructions a {
    background-color: #eee;
    padding: 5px 10px;
    display: inline-block;
}

.mobApplyFilter {
    text-align: center;
    cursor: pointer;
    float: right;
    width: 100%;
}

.mobApplyFilter.clearFiltersOpen {
    width: 60%;
}

.touch-device .QuickBuyAndWishListContainerDiv,
.touch-device .AlternateImageContainerDiv {
    display: none !important;
}

.mobClearFilters {
    width: 40%;
    float: left;
    border-right: 1px solid #fff;
    text-align: center;
    cursor: pointer;
}

.mobAppliedFilters {
    height: 15px;
    margin-right: 15px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    text-overflow: ellipsis;
}

.mobAppliedFilters span.seperator::before {
    content: " ,";
}

.Responsive .mobCbRow {
    display: block;
}

.columnsmobile2 .s-productthumbbox .col-xs-6 {
    width: 100% !important;
}

.Responsive .mobDdClose {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 4px;
    margin: 0;
    border-left: 1px solid #ddd;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
}

.Responsive .mobDdClose .clsFilterIcon {
    top: 0;
    line-height: normal;
    vertical-align: middle;
}

.Responsive .refineByLeftHeader {
    position: relative;
}

.MobSortSelector {
    margin-bottom: 12px;
    border: 1px solid #ddd;
}

.MobSortSelector input[type="radio"] {
    display: none;
}

.MobSortSelector label {
    font-weight: normal;
    display: block;
    cursor: pointer;
    font-size: 14px;
}

.mobFilterContainer {
    height: 100%;
    position: relative;
    width: 100%;
}

.MobFiltersText {
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    vertical-align: top;
    display: none;
    letter-spacing: 0.1em;
}

.FiltersTitle .MobFiltersText {
    color: #000;
}

#mobFilterControls {
    clear: both;
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 56px;
}

#mobFilterControls li {
    height: 100%;
    position: relative;
}

#mobFilterControls li .textIconWrap {
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}

#mobFilterControls li span {
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    vertical-align: top;
    letter-spacing: 0.1em;
}

#mobFilterControls li span.glyphicon {
    margin-right: 5px;
    height: 16px;
    width: 16px;
    line-height: normal;
    top: auto;
}

.mobFilterAppIcon {
    display: none;
    height: 19px;
    width: 19px;
    vertical-align: top;
    top: auto;
    line-height: normal;
    margin-left: 5px;
    color: #fff;
}

.mobFilterAppIcon.glyphicon.glyphicon-ok-sign {
    display: inline-block !important;
}

.mobFilterAppIcon.glyphicon.glyphicon-ok-sign::before {
    vertical-align: middle;
}

#FilterContainer,
#ProductContainer {
    padding: 0;
}

.paginationWrapper {
    padding-top: 10px;
    padding-bottom: 20px;
}

.sdlProdList .TextSizeWrap {
    text-align: left;
}

.sdlProdList .s-productthumbbox .s-producttext-top-wrapper {
    font-size: 1.2em;
}

.sdlProdList .s-productthumbbox .s-producttext-top-wrapper a span {
    display: block;
}

.sdlProdList .productdescriptionbrand {
    font-weight: 700;
    font-size: 1.2em;
    padding-bottom: 3px;
}

.sdlProdList .Responsive .RefandPrice span {
    font-size: 1.3em;
}

.refineByLeftHeader h2,
.ChildCategoriesListWrapper h2 {
    font-size: 1.4em;
}

.s-productthumbbox .s-productthumbimage .ProductImageList > div:first-of-type {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
}

.sdlProdList .s-productthumbbox .s-productthumbimage .ProductImageList {
    max-width: 265px;
    width: 100%;
}

/* Reserve space for product image that is lazy loaded or where aspect-ratio is not supported */
.s-productthumbbox .s-productthumbimage .ProductImageList > div:first-of-type::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
}

.s-productthumbbox .s-productthumbimage .ProductImageList > div:first-of-type::after {
    display: block;
    content: "";
    clear: both;
}

@media (min-width: 1022px) {
    body.no-scroll.Browse {
        position: static;
        height: auto;
        width: auto;
        overflow: auto;
    }

    #innerfiltercontainer {
        padding: 0 5px;
        max-width: 100%;
    }

    .uscProdList #innerfiltercontainer,
    .flanProdList #innerfiltercontainer {
        padding: 0;
    }

    .spfxProdList #innerfiltercontainer {
        padding: 0 15px;
    }

    .AltProdList .FilterProductsTable {
        display: flex;
        width: 100%;
    }

    .AltProdList #FilterContainer,
    .spfxProdList #FilterContainer {
        background-color: #f1f1f1;
        vertical-align: top;
        max-height: 100% !important;
    }

    .AltProdList ul.productFilters > li {
        padding: 5px 0;
    }

    .AltProdList #FiltersHeader,
    .AltProdList #divFindOutMoreHeader {
        padding: 15px 0;
    }

    .AltProdList #lblCategoryCopy {
        display: block;
        max-width: 1240px;
        margin: auto;
    }

    .AltProdList #innerfiltercontainer #lblCategoryCopy {
        max-width: none;
        padding: 0 15px 15px;
    }

    .AltProdList #innerfiltercontainer #lblCategoryCopy img {
        display: none;
    }

    #FilterContainer {
        padding-right: 5px;
    }

    #ProductContainer,
    .spfxProdList .productsWrap {
        padding-left: 5px;
    }

    .singleBrowseBar #FilterContainer #innerfiltercontainer {
        padding: 0;
    }

    .Originale #FilterContainer,
    .Originale #innerfiltercontainer {
        max-height: none !important;
    }

    .MobSortSelector {
        display: none;
    }

    .refineByLeftHeader h2 {
        text-transform: uppercase;
        padding: 5px 0 2px;
    }

    .productFilterList {
        max-height: 288px;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid #ddd;
    }

    .singleBrowseBar .productFilterList {
        max-height: none;
        overflow-y: initial;
        border-bottom: 0;
    }

    .sdlProdList .ChildCategoriesListWrapper h2 {
        line-height: 24px;
        padding: 17px 0 10px;
        font-size: 1.3em;
    }

    .sdlProdList #FiltersHeader {
        padding: 17px 0 10px;
    }

    .sdlProdList .refineByLeftHeader::before,
    .sdlProdList .refineByLeftHeader::after {
        content: " ";
        display: table;
    }

    .sdlProdList .refineByLeftHeader::after {
        clear: both;
    }

    .sdlProdList .refineByLeftHeader h2 {
        line-height: 24px;
        padding: 0;
        float: left;
    }

    .sdlProdList #FilterContainer {
        border-top: 1px solid #ddd;
        padding: 0;
        background-color: transparent;
    }

    .sdlProdList #ProductContainer {
        padding-left: 10px;
        border-top: 1px solid #ddd;
    }

    .sdlProdList .s-productthumbbox .s-producttext-top-wrapper {
        font-size: 1.2em;
    }

    .crusProdList .columns3 .s-productthumbbox .s-productthumbimage .hotspotbuy.hotspotquickbuy,
    .crusProdList .columns3 .s-productthumbbox .s-productthumbimage .hotspotbuy.hotspotwishlist {
        max-width: 265px;
    }
}

#PriceFilterTextEntryMin,
#PriceFilterTextEntryMax {
    width: 31%;
    text-align: center;
    float: left;
    height: 38px;
    border: 1px solid #ccc;
}

#PriceFilterTextEntryMin:hover,
#PriceFilterTextEntryMax:hover,
#PriceFilterTextEntryMin:focus,
#PriceFilterTextEntryMax:focus {
    border: 1px solid #aaa;
}

#PriceFilterTextEntry {
    margin: 10px 0;
    padding: 0;
}

#PriceFilterTextEntry .ToText {
    float: left;
    font-size: 1.1em;
    padding: 11px 2px 0;
    text-align: center;
}

#PriceFilterTextEntryApply {
    border-radius: 50%;
    width: 38px;
    float: right;
    height: 38px;
    padding: 0;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

#PriceFilterTextEntryError {
    padding: 5px;
    background-color: #fff;
    border: none;
}

.AltProdList .ContentWrapper {
    max-width: 1600px;
}

.hotspotbuy {
    cursor: pointer;
}

.s-productthumbbox .hotspotbuy.hotspotsaveforlater {
    display: none !important;
}

.ChildCategoriesListWrapper {
    padding: 10px 0 20px;
}

.ChildCategoriesListWrapper h2 {
    font-weight: bold;
    padding: 10px 0;
    line-height: 1em;
    text-transform: uppercase;
}

.ChildCategoriesListWrapper #ChildCategoriesList {
    max-height: 250px;
    overflow-y: auto;
}

.ChildCategoriesListWrapper #ChildCategoriesList li a {
    padding: 5px 15px 5px 0;
    display: block;
    position: relative;
    font-size: 1.1em;
}

.ChildCategoriesListWrapper #ChildCategoriesList li a:hover {
    text-decoration: underline;
}

.ChildCategoriesListWrapper #ChildCategoriesList li a::after {
    content: "";
    width: 10px;
    height: 15px;
    right: 5px;
    position: absolute;
    top: 5px;
    font-size: 11px;
}

@media (max-width: 1021px) {
    ul.productFilters > li {
        border: 1px solid #ddd;
        margin-bottom: 12px;
        padding: 0;
    }

    .brandSearchSort {
        padding: 0 10px;
    }

    #PriceFilterTextEntry {
        padding: 10px;
    }

    .FiltersText {
        display: none;
    }

    #divFindOutMoreHeader {
        display: none;
    }

    .productFilterList {
        display: none;
        border-top: 1px solid #ddd;
    }

    .Responsive #FilterContainer {
        position: fixed;
        background: #fff;
        bottom: 0;
        left: 0;
        z-index: 10061 !important;
        border: 0;
        box-shadow: 0 0 1px 1px #ddd;
        width: 100%;
        overflow: hidden;
    }

    .Responsive #FilterContainer.MobClosed {
        display: none;
    }

    .Responsive #FilterContainer #innerfiltercontainer {
        width: 100% !important;
        height: 100%;
        top: 0 !important;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative !important;
        -webkit-overflow-scrolling: touch;
        padding: 10px 10px 76px;
    }

    .Responsive #FilterContainer #showFilterButtonDiv {
        display: none;
    }

    .Responsive .productFilterList .FilterAnchor {
        padding: 6px 10px;
    }

    .Responsive .SliderContain {
        padding: 0 20px 10px 15px;
    }

    .Responsive .s-left-header {
        margin: 0 0 10px;
        padding: 0;
    }

    .Responsive .s-left-header h2 {
        padding: 0 30px;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-align: center;
        text-transform: uppercase;
    }

    .Responsive .refineByLeftHeader h3 {
        font-size: 1.5em;
        float: none;
        text-align: center;
    }

    .Responsive .productFilterTitleBox img {
        padding-top: 0;
    }

    .productFilterTitleBox {
        background-color: #fff;
    }

    .Responsive .FilteringApplied .productFilterTitleBox {
        background: #ececec;
    }

    .Responsive .productFilterTitleBox,
    .productFilterTitleBoxCLOSED {
        padding: 5px 10px;
    }

    .Responsive .productFilterTitle {
        text-transform: none;
        padding: 0 0 4px;
    }

    #clrallfltrs {
        display: none !important;
    }

    .Browse:not(.sdlProdList) .back-to-top {
        display: none !important;
    }

    .ChildCategoriesListWrapper + .refineByLeftHeader {
        position: static;
    }

    .ChildCategoriesListWrapper + .refineByLeftHeader .mobDdClose {
        top: 10px;
        right: 10px;
        border: none;
    }

    .ChildCategoriesListWrapper h2 {
        padding: 0 30px;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 10px;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li {
        min-height: 28px;
        margin-bottom: 5px;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li a {
        padding: 5px 10px;
    }

    .ChildCategoriesListWrapper #ChildCategoriesList li a::after {
        width: 32px;
        height: 18px;
        right: 0;
    }

    .crusProdList .Responsive #FilterContainer {
        box-shadow: 0 0 1px 1px #000;
        max-height: calc(100% - 113px);
        top: auto !important;
        width: 100% !important;
    }

    .crusProdList .Responsive .productFilterTitleBox,
    .crusProdList .productFilterTitleBoxCLOSED {
        padding: 15px;
    }

    .crusProdList .Responsive .productFilterTitle,
    .crusProdList .Responsive .mobAppliedFilters {
        text-transform: uppercase;
        font-size: 1.3em;
    }

    .crusProdList .ChildCategoriesListWrapper {
        padding-top: 20px;
    }

    .crusProdList .ChildCategoriesListWrapper h2 {
        text-align: left;
        padding: 0 20px;
    }

    .crusProdList .ChildCategoriesListWrapper #ChildCategoriesList {
        padding-left: 25px;
    }

    .crusProdList .s-productthumbbox .hotspotbuy.hotspotquickbuy {
        display: none;
    }
}

@media (max-width: 767px) {
    #lblCategoryCopy {
        display: none;
    }

    .evanProdList #lblCategoryCopy {
        display: block;
    }

    .visible-xs.layout {
        margin-bottom: 20px;
        border: 0;
    }

    #mobControlBar .layout li {
        width: 40%;
        margin-right: 0;
    }

    .s-producttext-price .s-largered span {
        font-size: 1.6em;
    }

    .columnsmobile2 .s-productscontainer2 > li {
        width: 50%;
    }

    .sdlProdList .columnsmobile1 .s-productscontainer2 > li {
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
    }

    .FilterProductsTable .columnsmobile1 .s-productscontainer2 > li {
        width: 100%;
    }

    .pagination .PageNumber {
        text-align: center;
        line-height: 2em;
    }

    .Responsive .pagination-bottom .PageFromTo {
        height: auto;
        border: none;
        padding: 0 0 10px;
        text-align: center;
    }

    .Responsive .pagination-bottom .ViewPerPage {
        height: 50%;
        border: none;
        text-align: right;
    }

    .columnsmobile1 .s-productthumbtext {
        padding: 0 5px;
    }

    .sdlProdList .columnsmobile2 .s-productthumbbox .s-producttext-top-wrapper,
    .sdlProdList .columnsmobile2 .s-productthumbbox .s-producttext-price,
    .sdlProdList .columnsmobile2 .s-productthumbbox .s-productthumb-extra .s-productsize {
        max-width: 265px;
        margin: 0 auto;
    }

    .columnsmobile1 .s-producttext-top-wrapper {
        padding: 5px 0;
    }

    .columnsmobile1 .s-productthumbbox .s-productthumbimage .ProductImageList img {
        min-height: 128px;
    }

    .columnsmobile2 .s-productthumbbox .s-productthumbimage .ProductImageList img {
        min-height: 125px;
    }

    .AltProdList .columnsmobile1 .s-productthumbbox .s-productthumbimage .ProductImageList img {
        min-height: 124px;
    }

    .AltProdList .columnsmobile2 .s-productthumbbox .s-productthumbimage .ProductImageList img {
        min-height: 113px;
    }

    .columnsmobile1 .s-productthumbbox .s-productthumb-extra .s-productsize {
        padding: 0;
    }

    .Responsive .RefandPrice span {
        font-size: 1.1em;
    }

    .columnsmobile1 .s-productthumbbox .s-producttext-price {
        padding: 4px 0 0;
    }

    .columnsmobile1 .s-productthumb-extra {
        padding: 5px 5px 0;
    }

    .Responsive .topheadbox {
        margin: 0;
    }
}

.img-hide {
    visibility: hidden;
}

.productFilterTitleBox .glyphicon {
    float: right;
    height: 21px;
    font-size: 1.2em;
    text-align: right;
    width: 21px;
}

.productFilterTitleBox .glyphicon::before {
    content: "\e114";
}

.productFilterTitleBox.FilterClosed .glyphicon::before {
    content: "\e080";
}

.singleBrowseBar .productFilterClear a:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .Browse #lblCategoryCopy {
        display: block;
    }

    .sdlProdList .TextSizeWrap {
        text-align: center;
    }

    .sdlProdList .s-productthumbbox .s-producttext-top-wrapper {
        font-size: 1.2em;
    }

    .sdlProdList .Responsive .RefandPrice span {
        font-size: 1.2em;
    }
}

@media (min-width: 1025px) {
    .singleBrowseBar .productFilterTitleBox .glyphicon {
        height: 15px;
        width: 13px;
        padding-right: 0;
    }

    .singleBrowseBar h2 {
        font-size: 1.2em;
        padding: 10px 0;
        line-height: 1.3em;
        display: inline-block;
    }

    .singleBrowseBar #FiltersHeader {
        border-bottom: none;
        border-right: 1px solid #ccc;
        float: left;
    }

    .singleBrowseBar ul.productFilters > li {
        border-right: 1px solid #e5e5e5;
    }

    .singleBrowseBar ul.productFilters > li:last-child {
        border-right: 0;
    }

    .singleBrowseBar .FilterListColumn {
        float: left;
        padding-right: 8px;
    }

    .singleBrowseBar #TopPaginationWrapper.sticky,
    .singleBrowseBar #FilterContainer.sticky {
        position: fixed;
        background-color: rgba(255, 255, 255, 0.95);
    }

    .singleBrowseBar #TopPaginationWrapper.sticky {
        z-index: 1005;
    }

    .singleBrowseBar #FilterContainer.sticky {
        z-index: 1100;
    }

    .singleBrowseBar .s-maincontent-container .pagination {
        padding: 0 0 10px;
    }

    .singleBrowseBar .productFilterList {
        top: 35px;
        display: none;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.95);
        z-index: 960;
        margin: 0;
        min-width: 320px;
        border: 1px solid #dedede;
        border-width: 0 1px 1px;
        padding: 12px;
        box-shadow: 0 1px 0 #ccc;
    }

    .singleBrowseBar .productFilterListTwoColumns {
        width: 425px;
    }

    .singleBrowseBar .productFilterListTwoColumns .FilterListColumn {
        width: 50%;
    }

    .singleBrowseBar #innerfiltercontainer,
    .singleBrowseBar .pagination-bottom {
        width: 100%;
        padding: 6px 0;
        border-bottom: 2px solid #e1e1e1;
        border-top: 2px solid #e1e1e1;
        box-shadow: 0 5px 7px -6px #bdbdbd;
    }

    .singleBrowseBar .ProductsFiltered .FiltersTitle {
        display: none;
    }

    .singleBrowseBar .ProductsFiltered #clrallfltrs {
        display: block;
        margin-top: 5.5px;
        margin-bottom: 5.5px;
    }

    .singleBrowseBar .s-maincontent-container .pagination-bottom {
        padding: 5px 6px 10px 0;
    }

    .singleBrowseBar #FilterContainer {
        width: 100%;
        float: none;
        margin-bottom: 30px;
        display: block;
        height: auto !important;
    }
}

.singleBrowseBar .pagination .PageFromTo {
    border: none;
}

.singleBrowseBar .sortbyfilter {
    border: none;
    text-align: left;
    position: static;
    left: 0;
    z-index: 1;
    height: auto;
}

.singleBrowseBar #TopPaginationWrapper .layout {
    text-align: left;
}

.singleBrowseBar #FiltersAndProductsWrapper {
    position: relative;
    display: block;
}

.singleBrowseBar #FiltersHeader {
    padding: 0 15px 0 0;
    width: 10%;
    margin: 0;
}

.singleBrowseBar .filterlist {
    float: left;
}

.singleBrowseBar ul.productFilters > li {
    float: left;
    padding: 0;
    margin-bottom: 0;
    position: relative;
}

.singleBrowseBar .productFilterTitleBox {
    padding: 10px;
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.singleBrowseBar .FilteringApplied {
    background: #fff;
}

.singleBrowseBar ul.productFilters > li .ImageFilter {
    display: none;
}

.singleBrowseBar ul.productFilters > li h3 {
    padding: 0;
    float: none;
    overflow: hidden;
    height: 100%;
    margin: 0;
    font-weight: normal;
}

.singleBrowseBar .FilteringApplied h3 {
    font-weight: bold;
}

.singleBrowseBar ul.productFilters > li:last-child .productFilterList,
.singleBrowseBar ul.productFilters > li:nth-last-child(2) .productFilterList {
    right: 0;
}

.singleBrowseBar #ProductContainer {
    width: 100%;
    display: block;
    padding: 0;
}

.singleBrowseBar .FilteringApplied .clearfiltersbottom {
    display: block;
    float: left;
    padding-top: 10px;
}

.singleBrowseBar .productFilters {
    float: left;
    width: 90%;
}

.singleBrowseBar .paginationWrapper .pagination {
    padding-left: 0;
    padding-right: 0;
}

.singleBrowseBar #TopPaginationWrapper {
    margin-bottom: 5px;
}

.singleBrowseBar .paginationWrapper {
    margin-top: 5px;
    margin-bottom: 5px;
}

.singleBrowseBar .columns3 .s-productthumbbox .s-productthumbimage .ProductImageList img {
    min-height: 254px;
}

@media (max-width: 1024px) {
    .touchenabled .s-productthumbbox .hotspotbuy {
        display: none !important;
    }

    .singleBrowseBar .MobSortSelector {
        display: block;
    }

    .singleBrowseBar .MobFiltersText {
        display: inline-block;
    }

    .singleBrowseBar .FiltersText {
        display: none;
    }

    .singleBrowseBar ul.productFilters > li {
        border: 1px solid #ddd;
    }

    .singleBrowseBar #FilterContainer #innerfiltercontainer {
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        width: 100%;
        position: relative;
        padding: 10px 10px 76px;
        height: 100%;
        top: 0 !important;
    }

    .singleBrowseBar .refineByLeftHeader h2 {
        padding: 0 30px;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-align: center;
    }

    .singleBrowseBar .productFilterList .FilterAnchor {
        padding: 6px 8px;
    }

    .singleBrowseBar .FilterListItem a.greyOut {
        opacity: 1;
    }

    .singleBrowseBar .SliderContain {
        padding: 0 20px 10px 15px;
    }

    .singleBrowseBar .s-left-header {
        margin: 6px 0 12px;
        border: 1px solid #ccc;
    }

    .singleBrowseBar ul.productFilters > li h3 {
        font-weight: bold;
    }

    .singleBrowseBar .refineByLeftHeader h3 {
        font-size: 1.5em;
        float: none;
        text-align: center;
    }

    .singleBrowseBar .productFilterTitleBox img {
        padding-top: 0;
    }

    .singleBrowseBar .FilteringApplied .productFilterTitleBox {
        background: #ececec;
    }

    .singleBrowseBar .productFilterTitleBox,
    .productFilterTitleBoxCLOSED {
        padding: 6px 8px;
    }

    .singleBrowseBar .productFilterTitle {
        font-size: 1.1em;
        text-transform: none;
        padding: 0 0 4px;
    }

    .singleBrowseBar .SelectableFilter,
    .singleBrowseBar .SelectedFilter {
        font-size: 1.1em;
    }

    .singleBrowseBar #FilterContainer.MobClosed {
        display: none;
    }

    .singleBrowseBar #FilterContainer {
        position: fixed;
        background: #fff;
        top: auto !important;
        bottom: 0;
        left: 0;
        padding: 0;
        z-index: 10061 !important;
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch;
        width: 100% !important;
    }

    .singleBrowseBar #clrallfltrs {
        display: none !important;
    }

    .singleBrowseBar .PageNumber {
        text-align: center;
        font-size: 1.4em;
        position: relative;
        width: 100%;
    }

    .singleBrowseBar .PageNumberInner {
        width: 100%;
        line-height: 1em;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .singleBrowseBar #mobControlBar .layout li {
        padding: 1px 0 0;
    }

    .singleBrowseBar .columns3 .s-productthumbbox .s-productthumbimage .ProductImageList img {
        min-height: 224px;
    }

    .singleBrowseBar .PageNumber {
        font-size: 1.3em;
        width: 100%;
    }

    .singleBrowseBar .breadcrumb {
        margin-top: 30px !important;
    }

    .singleBrowseBar #FiltersHeader {
        margin: 5px 0 10px;
        padding: 0;
        width: 100%;
    }

    .singleBrowseBar .productFilters {
        display: inline-block;
        width: 100%;
    }

    .singleBrowseBar ul.productFilters > li {
        float: none;
        margin-bottom: 10px;
    }

    .singleBrowseBar .productFilterTitleBox {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .singleBrowseBar .columnsmobile2 .s-productthumbbox .s-productthumbimage .ProductImageList img {
        min-height: 129px;
    }

    .singleBrowseBar #FiltersHeader {
        margin: 5px 0 10px;
        padding: 0;
        width: 100%;
        float: none;
    }

    .singleBrowseBar .productFilters {
        display: inline-block;
        width: 100%;
    }

    .singleBrowseBar ul.productFilters > li {
        float: none;
        margin-bottom: 10px;
    }

    .singleBrowseBar .productFilterTitleBox {
        text-align: left;
    }
}

#divBrandSearch {
    position: relative;
    width: 100%;
    margin: 0 0 10px;
}

#divBrandSearch input {
    width: 100%;
    height: 38px;
    margin: 0;
    padding: 5px 10px;
    border: 1px solid #ddd;
    appearance: none;
    border-radius: 0;
}

#divBrandSearch input:hover,
#divBrandSearch input:focus {
    border: 1px solid #aaa;
}

#divBrandSearch input::-ms-clear {
    display: none;
}

.SortQtyName {
    margin-bottom: 10px;
    font-size: 14px;
}

#divBrandNotFound {
    font-size: 1.1em;
    margin-top: 10px;
}

.SortQtyName span:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 5px;
}

.SortQtyName a:hover {
    text-decoration: underline;
}

#spnClearBrandFilter {
    padding: 0;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    font-size: 1.4em;
    right: 35px;
    height: 100%;
    line-height: 38px;
}

#spnSearchIcon {
    position: absolute;
    width: 30px;
    top: 1px;
    right: 0;
    text-align: center;
    line-height: 36px;
}

#spnSearchIcon .glyphicon {
    top: 0;
    vertical-align: middle;
    font-size: 1.3em;
}

.quickBuyModal .modal-header {
    padding: 10px 40px;
    line-height: 1.3em;
    height: 49px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.quickBuyModal .header-text {
    font-size: 1.3em;
    color: #666;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
}

.quickBuyModal .modal-dialog {
    width: 100%;
    max-height: 446px;
    max-width: 520px;
}

.quickBuyModal .modal-body {
    height: 400px;
    width: 100%;
    max-height: 400px;
    max-width: 520px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 380px) {
    .Responsive .mobFilterContainer.MobSlide,
    .Responsive .mobSortContainer.MobSlide {
        left: 100%;
    }
}

.s-producttext-top-wrapper a:hover span span {
    text-decoration: underline;
}

@media (max-width: 1021px) {
    .sdlProdList #mobFilterControls li span.glyphicon {
        display: none;
    }

    .sdlProdList .productFilterList .FilterAnchor .MobSortSelectionCheckbox {
        width: calc(100% - 20px);
        padding: 5px 15px;
        line-height: 15px;
    }

    .sdlProdList .MobSortSelector label {
        margin-bottom: 0;
        color: #636363;
    }

    .sdlProdList .MobSortSelector label:hover {
        text-decoration: underline;
    }

    .MobFiltersText {
        display: inline-block;
    }
}

.MobInstructions {
    display: none !important;
}

.showFilterButtonWrapper .showFilterButton {
    width: 100%;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 1.2em;
    font-weight: bold;
    border: 0;
    padding: 15px 5px;
    margin: 0;
    line-height: 1.3em;
}

.showFilterButtonWrapper {
    padding: 20px 10px;
}

.rollUpQuickBuyWrap .product-rollup-more {
    text-align: center;
}

.rollUpQuickBuyWrap .product-rollup {
    display: flex;
    text-align: center;
    margin: 0 auto;
    max-width: 200px;
    justify-content: center;
}

.rollUpQuickBuyWrap .product-rollup .product-rollup-variant {
    padding: 0;
    flex-basis: 25%;
    width: 25%;
    display: inline-block;
}

.rollUpQuickBuyWrap .product-rollup .product-rollup-variant a {
    display: block;
    padding: 3px;
}

.rollUpQuickBuyWrap .product-rollup-more a {
    display: inline-block;
    font-size: 1.1em;
    text-transform: uppercase;
    color: #333;
    padding: 3px;
}

.rollUpQuickBuyWrap .product-rollup-more a:hover {
    font-weight: bold;
}

.rollUpQuickBuyWrap .product-rollup-more a .MoreRollupThumbnail {
    border-bottom: 1px solid #333;
}

.BrowseRollupThumbnail {
    width: 100%;
    display: block;
    padding-top: 100%;
    max-height: 44px;
}

.BrowseRollupThumbnail.background-contain {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.TextSizeWrap > .reviews-container {
    text-align: center;
    float: left;
    width: 100%;
}

.spfxProdList .s-product-sache {
    top: 0;
    right: 5px;
}

.spfxProdList .s-productthumbbox .s-productthumbimage {
    padding-top: 5px;
}

.spfxProdList .s-productthumb-extra {
    position: relative;
    width: 80%;
    float: left;
}

.donnProdList .HeadnCount .pppt {
    line-height: 28px;
}

.uscProdList.Browse .HeadnCount h1 {
    text-transform: uppercase;
    font-size: 1.9em;
    padding: 15px;
    width: 100%;
}

.uscProdList #innerfiltercontainer {
    text-align: left;
}

.uscProdList.Browse .HeadnCount {
    text-align: center;
    position: static;
    width: 100%;
    padding: 0;
    top: auto;
    margin: 0;
}

.uscProdList .catTemplate .textDesc {
    padding: 10px;
}

.uscProdList .catTemplate .siblingLinks {
    margin: 0;
}

.uscProdList .catTemplate .viewMore a {
    cursor: pointer;
}

.uscProdList.Browse .categorycopyd4 {
    text-align: center;
    padding: 0 0 10px;
}

.uscProdList .s-left-header {
    border: 0;
    margin: 0;
    padding: 20px 0 10px;
}

.uscProdList .productFiltersReset a {
    border: 0;
}

.uscProdList .productFilterTitle {
    text-transform: uppercase;
}

.uscProdList .mobDdClose {
    border: 0;
    height: 30px;
    width: 30px;
    padding: 0;
    right: 15px;
    top: 10px;
}

.uscProdList .mobAppliedFilters {
    color: #fff;
    text-transform: uppercase;
}

.uscProdList .FilterClosed .mobAppliedFilters {
    color: #999;
}

.uscProdList .MobSortSelector,
.uscProdList ul.productFilters > li {
    margin: 0;
    border: 0;
}

.uscProdList .FilterListItem a.greyOut {
    opacity: 1;
}

.uscProdList .FilterListItem a:hover * {
    text-decoration: none;
}

.uscProdList .productFilterList {
    border: 0;
    padding: 0;
}

.uscProdList #mobFilterControls li span.glyphicon {
    display: none !important;
}

.uscProdList .mobFilterAppIcon.glyphicon-ok-sign::before {
    width: 19px;
    content: "";
    display: block;
    height: 19px;
    font-size: 11px;
    background-size: 400px 400px;
    background-position: -295px -228px;
}

.uscProdList #mobFilterControls li#mobclrfltrs {
    width: 40%;
    background-color: #eee;
}

.uscProdList #mobFilterControls li#mobclrfltrs span {
    color: #000;
}

.uscProdList.Browse .PageFromTo {
    border: 0;
    float: none;
    height: auto;
    padding: 0;
    display: inline-block;
}

.uscProdList .productFiltersReset {
    float: none;
    border: 0;
    font-size: 1.1em;
    text-transform: uppercase;
    padding: 20px 15px;
    text-decoration: none;
}

.uscProdList .SelectableFilter,
.uscProdList .SelectedFilter {
    background: none;
    padding: 0;
    font-size: 0.65em;
}

.uscProdList .s-productthumbbox .TextSizeWrap {
    margin-top: 15px;
    margin-bottom: 25px;
}

.uscProdList .s-productthumbbox .s-productthumbimage .ProductImageList img {
    width: 95%;
    max-width: 350px;
}

.uscProdList .s-productthumbbox .s-productthumbimage .ProductImageList {
    width: 100%;
}

.uscProdList .SelectedFilter::before,
.uscProdList .SelectableFilter::before {
    content: "";
    width: 13px;
    float: left;
    height: 13px;
    margin-top: 2px;
}

.uscProdList .SelectedFilter::before {
    background-size: 290px 290px;
    background-position: -215px -148px;
}

.uscProdList .SelectedFilter.MobSortSelectionCheckbox::before {
    display: none;
}

.uscProdList .productFilterList .FilterName {
    width: calc(75% - 13px);
    text-transform: uppercase;
    padding-left: 5px;
}

.uscProdList .productFilterList .FilterValue {
    padding-right: 5px;
}

.uscProdList .s-maincontent-container .pagination {
    line-height: 1em;
}

.uscProdList .pagination label {
    font-size: 1.3em;
    text-transform: uppercase;
    line-height: normal;
    letter-spacing: 0.1em;
    margin: 0;
}

.uscProdList .paginationWrapper {
    margin: 20px 0 30px;
}

.uscProdList .pagination .sortbyfilter {
    border: 0;
    float: right;
    width: auto;
}

.uscProdList .pagination .sortbyfilter label {
    font-size: 1.3em;
    text-transform: uppercase;
    line-height: 1em;
    margin: 0;
}

.uscProdList .pagination .sortbyfilter label::after {
    content: " - ";
}

.uscProdList .dropprods_Order {
    font-size: 1.3em;
    appearance: none;
    width: auto;
    height: auto;
    border: 0;
    line-height: normal;
    text-transform: uppercase;
    max-width: 155px;
    padding: 0 0 0 5px;
    background-color: #fff;
    border-radius: 0;
    white-space: normal;
}

.uscProdList .dropprods_Order::-ms-expand {
    display: none;
}

.uscProdList .dropprods_Order:hover,
.uscProdList .dropprods_Order:focus {
    border: 0;
}

.uscProdList .dropprods_Order option {
    font-size: 0.9em;
    text-transform: none;
}

.uscProdList .s-productthumbbox {
    border: 0;
}

.uscProdList .s-producttext-top-wrapper {
    padding: 0 0 5px;
    text-align: center;
}

.uscProdList .s-producttext-top-wrapper a:hover,
.uscProdList .s-producttext-top-wrapper a:hover span span {
    text-decoration: none;
}

.uscProdList .s-productthumbbox .s-producttext-top-wrapper a span {
    display: block;
}

.uscProdList .productdescriptionbrand {
    padding-bottom: 5px;
    text-transform: uppercase;
}

.uscProdList .productdescriptionname {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.uscProdList .s-productthumbbox .s-producttext-price {
    padding: 0;
    text-align: center;
}

.uscProdList .s-productthumbbox .s-largered,
.uscProdList .s-productthumbbox .RefandPrice {
    display: block;
    padding-right: 0;
}

.uscProdList .s-productthumbbox .s-largered .curprice {
    font-size: 1.1em;
    line-height: 1em;
}

.uscProdList .s-productthumbbox .RefandPrice .s-smalltext {
    padding-top: 5px;
    font-size: 1em;
    line-height: 1em;
    letter-spacing: 0.1em;
    text-decoration: line-through;
}

.uscProdList .pagination .PageNumber {
    text-align: center;
}

.uscProdList .PageNumber a,
.uscProdList .PageNumber span.PageSelector {
    line-height: 1em;
    min-width: 0;
    margin: 0;
    height: auto;
    padding: 10px;
    background-color: transparent;
}

.uscProdList .PageNumber a:hover,
.uscProdList .PageNumber span.PageSelector:hover {
    text-decoration: none;
}

.uscProdList .PageNumber a.NextLink,
.uscProdList .PageNumber a.PrevLink {
    border: 0;
    background-position: -378px -284px;
    background-size: 700px 700px;
    padding: 0;
    height: 32px;
    width: 32px;
}

.uscProdList .PageNumber a.PrevLink {
    background-position: -317px -284px;
}

.uscProdList .PageNumber a.NextLink:hover {
    background-position: -378px -322px;
}

.uscProdList .PageNumber a.PrevLink:hover {
    background-position: -317px -322px;
}

.uscProdList .productFilterList::-webkit-scrollbar {
    width: 5px;
}

.uscProdList .productFilterList::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.uscProdList .productFilterList::-webkit-scrollbar-thumb {
    background: #000;
}

.uscProdList .productFilterList::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.uscProdList .hotspotbuy.hotspotwishlist,
.uscProdList .hotspotbuy.hotspotquickbuy {
    bottom: 0;
    right: 5px;
    top: auto;
    display: none;
}

.uscProdList .hotspotbuy.hotspotwishlist {
    right: 5px;
    display: block !important;
}

.uscProdList .MobSortSelector label {
    margin: 0;
    width: calc(100% - 20px);
    float: right;
    text-transform: uppercase;
}

.uscProdList .Responsive .mobDdClose .clsFilterIcon {
    font-size: 11px;
    height: 100%;
    padding: 0;
    display: block;
    width: 100%;
    background-size: 500px 500px;
    background-position: -92px -199px;
}

.uscProdList .productFilterTitleBox .glyphicon::before {
    content: none;
}

.uscProdList .productFilterTitleBox .glyphicon {
    top: 0;
    font-size: 11px;
    padding: 0;
    display: inline-block;
    background-size: 500px 500px;
    background-position: -370px -319px;
}

.uscProdList .productFilterTitleBox.FilterClosed .glyphicon {
    background-position: -140px -202px;
}

.uscProdList .showFilterButtonWrapper .showFilterButton::after {
    content: "";
    position: absolute;
    bottom: -15px;
    width: 20px;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    height: 20px;
    background-size: 500px 500px;
    background-color: transparent;
    background-position: -141px -202px;
}

.uscProdList #divsiblinglistwrapper {
    padding: 0 5px;
}

.evanProdList #prdlistinformation {
    font-size: 0;
    margin-left: 8px;
}

.evanProdList #divBrandSearch input {
    border-radius: 10px;
}

.evanProdList #navlist > li {
    margin-bottom: 20px;
}

.evanProdList .s-productthumbbox .s-productthumbimage .ProductImageList {
    margin: 15px 0 40px;
}

.evanProdList .s-productthumbbox .s-productthumbimage .s-product-sache {
    top: auto;
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);
}

.evanProdList #productlistcontainer .s-producttext-top-wrapper {
    font-size: 1.25em;
    padding: 10px;
}

.evanProdList #productlistcontainer .s-producttext-top-wrapper a:hover,
.evanProdList .s-producttext-top-wrapper a:hover span span {
    text-decoration: none;
}

.evanProdList #productlistcontainer .s-producttext-top-wrapper .brandWrapTitle {
    display: block;
    font-size: 1.1em;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.evanProdList #filterlist .productFilter {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.evanProdList #filterlist .productFilter.FilterClosed {
    border-color: #fff;
}

.evanProdList .mobSortDesc .productFilterTitle {
    font-size: 1.3em;
    line-height: 1.3em;
}

.evanProdList .MobSortSelector {
    border-color: #005a54;
}

.evanProdList .SelectableFilter,
.evanProdList .SelectedFilter {
    position: relative;
    padding-left: 35px;
    background: none;
}

.evanProdList .productFilterList .FilterAnchor > span::before {
    display: block;
    position: absolute;
    left: 5px;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
}

.evanProdList .productFilterList .FilterAnchor > span.SelectedFilter::before {
    border-color: #005a54;
    background-color: #005a54;
}

.evanProdList .Responsive .FilterListItem a:hover .FilterName {
    text-decoration: none;
}

.evanProdList .productFiltersReset {
    float: none;
    padding: 10px 0;
    border-top: 1px solid #ddd;
}

.evanProdList .productFiltersReset a {
    text-transform: none;
    border: none;
    text-decoration: underline;
}

.evanProdList .ViewPerPage {
    border: none;
}

.evanProdList .ViewPerPage a {
    display: block;
    float: left;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: #c9c9c9;
    color: #000;
}

.evanProdList .ViewPerPage a:first-child {
    margin: 0;
}

.evanProdList .columns2 .s-productscontainer2 > li {
    width: 50%;
}

.evanProdList.Browse h1 {
    font-size: 2.4em;
}

@media (min-width: 1022px) {
    .uscProdList .hotspotbuy.hotspotquickbuy {
        display: block !important;
    }

    .uscProdList .hotspotbuy.hotspotwishlist {
        right: 35px;
    }

    .uscProdList ul.productFilters > li {
        padding: 15px;
        border-bottom: 1px solid #000;
        font-size: 15px;
    }

    .uscProdList .FilterProductsTable {
        border-top: 1px solid #ccc;
    }

    .uscProdList .refineByLeftHeader h2 {
        padding: 0;
        line-height: 1em;
    }

    .uscProdList .pppt {
        line-height: normal;
    }

    .uscProdList .pppt span {
        font-size: 1em;
        text-transform: none;
    }

    .uscProdList .pppt::before {
        content: " - ";
    }

    .uscProdList .productFilterTitle {
        width: 100%;
        font-size: 0.75em;
    }

    .uscProdList .productFilterTitleBox .mobSortDesc {
        display: block;
        width: 100%;
    }

    .uscProdList .productFilterTitleBox,
    .uscProdList .productFilterTitleBoxCLOSED {
        padding: 5px 0;
    }

    .uscProdList .productFilterTitleBox .glyphicon {
        display: none;
    }

    .evanProdList .refineByLeftHeader h2,
    .evanProdList #prdlistinformation {
        display: inline-block;
        padding: 10px 0;
    }

    .evanProdList .refineByLeftHeader h2,
    .evanProdList #prdlistinformation #prdsFound span {
        font-size: 14px;
        line-height: initial;
        vertical-align: bottom;
    }

    .evanProdList #prdlistinformation #prdsFound span.totalProducts {
        margin-right: 3px;
    }

    .evanProdList .refineByLeftHeader h2 {
        padding-left: 6px;
    }

    .evanProdList #filterlist .productFilter {
        padding-bottom: 10px;
    }
}

@media (max-width: 1021px) {
    .uscProdList .refineByLeftHeader {
        margin: 10px 0 15px;
        padding: 0;
    }

    .uscProdList .refineByLeftHeader h2 {
        text-align: left;
        padding: 0 15px;
    }

    .uscProdList .productFilterTitleBox,
    .uscProdList .FilteringApplied .productFilterTitleBox {
        background-color: #15abd2;
        transition: background-color 500ms linear;
    }

    .uscProdList .FilteringApplied .FilterClosed.productFilterTitleBox,
    .uscProdList .FilterClosed.productFilterTitleBox {
        background-color: transparent;
    }

    .uscProdList #FilterContainer {
        max-height: calc(100% - 70px);
        width: 100% !important;
    }

    .uscProdList #FilterContainer #innerfiltercontainer {
        padding-left: 0;
        padding-right: 0;
    }

    .uscProdList .productFilterTitleBox,
    .uscProdList .productFilterTitleBoxCLOSED {
        padding: 5px 15px;
    }

    .uscProdList .productFilterList {
        margin: 0 0 20px;
    }

    .uscProdList .SelectableFilter,
    .uscProdList .SelectedFilter {
        font-size: 1em;
    }

    .uscProdList .productFilterTitle {
        font-size: 1.2em;
        color: #fff;
        padding: 2px 0 0;
    }

    .uscProdList .FilterClosed .productFilterTitle {
        color: #000;
    }

    .uscProdList #lblCategoryCopy {
        display: block;
    }

    .evanProdList #filterlist.productFilters .productFilter {
        border: none;
    }

    .evanProdList #filterlist .productFilter .PriceRTag {
        padding: 0 10px;
    }
}

@media (min-width: 1022px) {
    .flanProdList #ToggleFiltersContainer,
    .flanProdList .ddlSortOptionsContainer,
    .evanProdList #ToggleFiltersContainer,
    .evanProdList .ddlSortOptionsContainer {
        position: relative;
    }

    .flanProdList .toggleFilterInner,
    .flanProdList .DesktopHide .toggleFilterInner,
    .flanProdList .ddlSortOptions,
    .evanProdList .toggleFilterInner,
    .evanProdList .DesktopHide .toggleFilterInner,
    .evanProdList .ddlSortOptions {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: all 400ms ease;
        background-color: #fff;
        z-index: 5001;
    }

    .flanProdList .toggleFilterInner {
        width: 100%;
    }

    .flanProdList .toggleFilterInner,
    .flanProdList .ddlSortOptionsOpen .ddlSortOptions,
    .evanProdList .toggleFilterInner,
    .evanProdList .ddlSortOptionsOpen .ddlSortOptions {
        visibility: visible;
        opacity: 1;
    }

    .flanProdList .ddlSortOptions,
    .evanProdList .ddlSortOptions {
        padding: 5px 10px;
        font-size: 0.8em;
        right: 0;
        left: auto;
        width: 100%;
        border: 1px solid #ccc;
        border-top: 1px solid #000;
    }

    .flanProdList .ddlSortOptions li span,
    .evanProdList .ddlSortOptions li span {
        letter-spacing: 0.05em;
        white-space: nowrap;
        position: relative;
        margin: 5px 0;
    }

    .flanProdList .toggleFilterInner .productFilters {
        border-bottom: 1px solid #000;
    }

    .flanProdList .refineByLeftHeader .productFiltersReset {
        float: none;
        line-height: 1.3em;
        text-align: right;
        border-left: 2px solid #000;
    }

    .flanProdList .refineByLeftHeader .productFiltersReset a {
        position: relative;
        border: 0;
        padding: 0;
    }

    .flanProdList #ProductContainer {
        padding: 0;
    }

    .flanProdList .sortbyfilter .sortOptionsContainer,
    .evanProdList .sortbyfilter .sortOptionsContainer {
        text-transform: uppercase;
        cursor: pointer;
        display: inline-block;
        font-size: 1.2em;
        vertical-align: middle;
        padding-right: 10px;
    }

    .flanProdList .sortbyfilter .sortOptionsHeader,
    .evanProdList .sortbyfilter .sortOptionsHeader {
        position: relative;
        line-height: 1em;
        min-width: 220px;
    }

    .flanProdList .sortbyfilter span,
    .evanProdList .sortbyfilter span {
        font-weight: normal;
        display: inline-block;
        vertical-align: top;
        line-height: 1.2em;
    }

    .flanProdList .sortbyfilter .lblSortBy::after {
        content: " | ";
    }

    .flanProdList .PageFromTo {
        height: auto;
        padding-top: 0;
        border: 0;
        line-height: 2.4em;
    }

    .flanProdList .PageFromTo .pppt {
        padding-left: 10px;
        line-height: 16px;
        vertical-align: middle;
        display: inline-block;
    }

    .flanProdList .PageFromTo .pppt span {
        font-size: 1em;
        letter-spacing: 0.1em;
    }

    .flanProdList .productFilters::before,
    .flanProdList .productFilters::after {
        content: " ";
        display: table;
    }

    .flanProdList .productFilters::after {
        clear: both;
    }

    .flanProdList .productFilterList {
        max-height: 215px;
    }

    .flanProdList ul.productFilters > li {
        width: 25%;
        float: left;
        padding: 15px 0;
        margin: 0;
        text-transform: uppercase;
    }

    .flanProdList .ProductFiltersCount-8 ul.productFilters > li {
        width: 12.5%;
    }

    .flanProdList .ProductFiltersCount-7 ul.productFilters > li {
        width: 14.285%;
    }

    .flanProdList .ProductFiltersCount-6 ul.productFilters > li {
        width: 16.666667%;
    }

    .flanProdList .ProductFiltersCount-5 ul.productFilters > li {
        width: 20%;
    }

    .flanProdList #FilterContainer {
        height: auto !important;
        padding: 0;
        margin-top: 15px;
    }

    .flanProdList .sortbyfilter {
        text-align: right;
    }

    .flanProdList .productFilterTitleBox,
    .flanProdList .productFilterTitleBoxCLOSED {
        padding: 5px 30px;
        cursor: text;
    }

    .flanProdList .refineByLeftHeader h2 {
        padding: 0 10px;
        cursor: pointer;
        display: inline-block;
        font-size: 1.2em;
        font-weight: normal;
        vertical-align: top;
    }

    .flanProdList .refineByLeftHeader .SelectedFiltersContainer,
    .flanProdList .refineByLeftHeader .SelectedFiltersWrapper {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 120px);
    }

    .flanProdList .SelectedFiltersContainer li.selectedFilter {
        display: inline-block;
    }

    .flanProdList .SelectedFiltersContainer li.selectedFilter a,
    .evanProdList .SelectedFiltersContainer li.selectedFilter a {
        display: block;
        padding: 0 15px 10px;
        cursor: pointer;
    }

    .flanProdList .SelectedFiltersContainer li.selectedFilter .selectedFilterLabel {
        text-transform: uppercase;
        font-size: 1.1em;
        line-height: 1.3em;
        display: inline-block;
    }

    .flanProdList .SelectedFiltersContainer li.selectedFilter .selectedFilterLabel:hover {
        text-decoration: underline;
    }

    .flanProdList .SelectedFiltersContainer li.selectedFilter .selectedFilterLabel::before {
        background-size: 700px 700px;
        content: "";
        width: 13px;
        vertical-align: top;
        height: 13px;
        margin-left: 0;
        margin-right: 5px;
        display: inline-block;
        background-position: -143px -176px;
    }

    .flanProdList .refineByLeftHeader h2 .refineIco,
    .flanProdList .sortbyfilter .sortOptionsHeader::before {
        background-size: 700px 700px;
        width: 15px;
        display: inline-block;
        vertical-align: top;
        height: 15px;
        background-position: -21px -177px;
    }

    .flanProdList .sortbyfilter .sortOptionsHeader::before {
        content: "";
    }

    .flanProdList .DesktopHide.refineByLeftHeader h2 .refineIco,
    .flanProdList .sortbyfilter .ddlSortOptionsOpen .sortOptionsHeader::before {
        background-position: -82px -177px;
    }

    .flanProdList .refineByLeftHeader h2 .FiltersText {
        display: inline-block;
        vertical-align: top;
        font-weight: normal;
    }

    .flanProdList .refineByLeftHeader h2 .SelectedFiltersContainer {
        display: inline-block;
    }

    .flanProdList .productFilterList .FilterAnchor {
        padding: 5px 15px;
        opacity: 1;
    }

    .flanProdList .productFilterList .FilterName {
        width: auto;
        float: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-left: 15px;
    }

    .flanProdList .toggleFilterInner > h2.FiltersTitle {
        text-align: right;
        text-transform: uppercase;
        padding: 0;
    }

    .flanProdList .toggleFilterInner > h2.FiltersTitle span {
        display: inline-block;
        padding: 10px 0;
        position: relative;
        cursor: pointer;
    }

    .flanProdList .fixed-filters-on .toggleFilterInner > h2.FiltersTitle span {
        padding: 10px;
    }
}

.flanProdList .pagination .PageNumber {
    margin: 0;
}

.flanProdList .pagination .PageNumber a {
    position: relative;
    width: auto;
}

.flanProdList.Browse h1 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.6em;
}

.flanProdList .productFilterList .FilterName::before,
.flanProdList .toggleFilterInner > h2.FiltersTitle span::before,
.flanProdList .ddlSortOptionsOpen .ddlSortOptions li span::before,
.flanProdList .refineByLeftHeader .productFiltersReset a::before,
.flanProdList .paginationWrapper .PrevNextButtons.PageNumber a::before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    bottom: -3px;
    left: 0;
    right: 100%;
    background-color: #000;
    transition: right 300ms ease-out 0s;
}

.flanProdList .paginationWrapper .PrevNextButtons.PageNumber a::before {
    left: 10px;
}

.flanProdList .productFilterList a:hover .FilterName::before,
.flanProdList .toggleFilterInner > h2.FiltersTitle span:hover::before,
.flanProdList .ddlSortOptionsOpen .ddlSortOptions li span:hover::before,
.flanProdList .refineByLeftHeader .productFiltersReset a::before {
    right: 0;
}

.flanProdList .paginationWrapper .PrevNextButtons.PageNumber a:hover::before {
    right: 10px;
}

.flanProdList .s-productthumbbox .s-productthumbimage .ProductImageList,
.flanProdList .s-productthumbbox .s-productthumbimage .ProductImageList img {
    width: 100%;
}

.flanProdList .s-productthumbbox .s-productthumbimage .ProductImageList {
    display: block;
}

.flanProdList .s-producttext-top-wrapper a:hover {
    text-decoration: none;
}

.flanProdList .s-productthumbtext {
    text-align: center;
}

.flanProdList .s-producttext-top-wrapper {
    padding: 10px 0 0;
}

.flanProdList .s-producttext-top-wrapper a span {
    display: block;
}

.flanProdList .s-producttext-top-wrapper a span span {
    padding-bottom: 10px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    font-size: 1.1em;
}

.flanProdList .s-producttext-top-wrapper .productdescriptionbrand {
    font-size: 1.1em;
}

.flanProdList .s-productthumbbox .s-producttext-price {
    padding: 0;
}

.flanProdList .s-productthumbbox .s-producttext-price .s-largered,
.flanProdList .s-producttext-price .RefandPrice {
    display: block;
    padding-right: 0;
    padding-bottom: 5px;
}

.flanProdList .s-productthumbbox .s-producttext-price .s-largered span {
    font-size: 1.1em;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    font-weight: normal;
}

.flanProdList .s-productthumbbox .s-producttext-price .RefandPrice span {
    font-size: 1.1em;
}

.flanProdList .s-productscontainer2 > li,
.evanProdList .s-productscontainer2 > li {
    padding: 0 1.2%;
}

.flanProdList .s-productthumbbox {
    border: 0;
    display: block;
    padding: 0 0 20px;
}

.flanProdList .productFilterTitleBox .glyphicon {
    display: none;
}

.flanProdList .productFilterList::-webkit-scrollbar {
    width: 5px;
}

.flanProdList .productFilterList::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.flanProdList .productFilterList::-webkit-scrollbar-thumb {
    background: #000;
}

.flanProdList .productFilterList::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.flanProdList .s-left-header,
.flanProdList .MobSortSelector,
.flanProdList ul.productFilters > li,
.flanProdList .productFilterTitleBox,
.flanProdList .FilteringApplied .productFilterTitleBox,
.flanProdList .productFilterList {
    border: 0;
}

.flanProdList .Responsive .productFilterList {
    padding: 0;
}

.flanProdList .productFilterTitle {
    width: 100%;
    font-weight: normal;
    font-size: 1.2em;
}

.flanProdList .SelectableFilter,
.flanProdList .SelectedFilter {
    background: none;
    padding: 0;
    position: relative;
}

.flanProdList .SelectableFilter::before,
.flanProdList .SelectedFilter::before {
    content: "";
    width: 12px;
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
    height: 12px;
}

.flanProdList .SelectedFilter::before {
    background-size: 700px 700px;
    background-position: -144px -177px;
}

.flanProdList .MobSortSelector .SelectedFilter::before,
.flanProdList .MobSortSelector .SelectableFilter::before {
    background: none;
}

.flanProdList .brandSearchSort {
    padding: 5px 30px;
}

.flanProdList .SortQtyName {
    margin-top: 10px;
}

.flanProdList .brandSearchSort #divBrandNotFound {
    margin-top: 10px;
    display: none;
}

.flanProdList .brandSearchSort input#txtBrandSearch {
    padding: 4px 22px 4px 8px;
    margin: 0;
}

.flanProdList .brandSearchSort #spnSearchIcon::before {
    height: 26px;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    content: "";
    background-size: 450px 450px;
    background-position: -81px -6px;
    font-size: 11px;
}

.flanProdList .brandSearchSort #spnSearchIcon .glyphicon {
    display: none;
}

.flanProdList .brandSearchSort #spnClearBrandFilter {
    left: calc(100% - 20px);
    right: auto;
}

.crusProdList .s-maincontent-container .pagination {
    margin: 0;
}

.crusProdList .pag-items-left {
    float: left;
    width: 50%;
}

.crusProdList .pag-items-right {
    float: right;
}

.crusProdList #divSortOptions {
    border: 1px solid #000;
    padding: 0;
    text-align: left;
    margin-right: 5px;
    width: 55%;
    max-width: 300px;
}

.crusProdList .pagination .sortbyfilter label {
    margin: 0;
    line-height: 38px;
    font-size: 1.2em;
    position: absolute;
    left: 15px;
    top: 1px;
}

.crusProdList .dropprods_Order {
    width: 100%;
    font-size: 1.2em;
    height: 39px;
    vertical-align: middle;
    text-indent: 70px;
}

.crusProdList .layout {
    float: left;
    margin-top: 3px;
}

.crusProdList .layout span.ViewbyCols {
    display: none;
}

.crusProdList .layout,
.crusProdList .layout li,
.crusProdList .layout li:hover,
.crusProdList .dropprods_Order {
    border: none;
}

.crusProdList #prdlistinformation {
    float: left;
}

.crusProdList #prdsFound {
    font-size: 1em;
    line-height: 37px;
}

.crusProdList #prdsFound span {
    font-size: 1.3em;
}

.crusProdList .nameWrapTitle,
.crusProdList .pagination .sortbyfilter label,
.crusProdList #prdsFound,
.crusProdList .dropprods_Order {
    text-transform: uppercase;
}

.crusProdList .s-producttext-price .s-largered span,
.crusProdList .pagination .sortbyfilter label {
    font-weight: normal;
}

.crusProdList .productFilterList .FilterName,
.crusProdList .productFilterList .FilterValue {
    width: auto;
    float: none;
}

.crusProdList .PageNumber a,
.crusProdList .PageNumber span.PageSelector {
    line-height: 38px;
    min-width: 20px;
    height: auto;
    margin: 0;
}

.crusProdList .pagination .PageSelector {
    background-color: transparent;
    font-weight: normal;
    position: relative;
}

.crusProdList .pagination .PageSelector::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 9px;
    left: 50%;
    height: 1px;
    width: 13px;
    background-color: #000;
    transform: translateX(-50%);
}

.crusProdList .PageNumber a.PrevLink,
.crusProdList .PageNumber a.NextLink {
    text-indent: 0;
    border: none;
    background: none;
    width: auto;
    padding: 0 5px;
    text-transform: uppercase;
}

.crusProdList .PageNumber a.PrevLink::before,
.crusProdList .PageNumber a.NextLink::after {
    content: "";
    display: inline-block;
    height: 15px;
    width: 10px;
    vertical-align: text-bottom;
    background-size: 400px 400px;
}

.crusProdList .PageNumber a.PrevLink::before {
    margin-right: 5px;
    background-position: -136px -121px;
}

.crusProdList .PageNumber a.NextLink::after {
    margin-left: 5px;
    background-position: -164px -121px;
}

.crusProdList .FilterListItem a.greyOut {
    opacity: 0.5;
}

.crusProdList #divBrandSearch input {
    padding: 5px 20px 5px 10px;
}

.crusProdList #divBrandSearch input:focus {
    outline: 1px solid #000 !important;
}

.crusProdList #spnClearBrandFilter {
    right: 10px;
}

.crusProdList .ChildCategoriesListWrapper #ChildCategoriesList li a {
    font-size: 1.16em;
}

.crusProdList .productFilterList .FilterAnchor .SelectedFilter {
    background-image: none;
    position: relative;
}

.crusProdList .productFilterList .FilterAnchor .SelectedFilter::before {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-size: 300px 300px;
    background-position: -174px -211px;
}

.crusProdList .MobSortSelector .productFilterList .FilterAnchor .SelectedFilter::before {
    display: none;
}

.crusProdList .s-productscontainer2 > li .s-productthumbbox {
    border: 1px solid transparent;
    padding: 10px;
    margin: 0 auto;
}

.crusProdList .s-productscontainer2 > li .s-productthumbbox:hover {
    border-color: #ccc;
}

.crusProdList .columns3 .s-productscontainer2 > li .s-productthumbbox {
    max-width: 287px;
}

.crusProdList .columns4 .s-productscontainer2 > li .s-productthumbbox {
    max-width: 221px;
}

.crusProdList .columns5 .s-productscontainer2 > li .s-productthumbbox {
    max-width: 184px;
}

.crusProdList .TextSizeWrap > .reviews-container {
    padding: 0 10px;
    text-align: left;
}

.crusProdList .s-productthumb-extra {
    font-size: 1em;
    text-align: center;
    padding: 0;
}

.crusProdList .s-productthumb-extra .sizeText {
    display: none;
}

.crusProdList .hotspotbuy .QuickLookIcon {
    display: none;
}

.crusProdList .hotspotbuy .QuickLookText,
.crusProdList .hotspotbuy .WishText {
    display: block;
    text-transform: uppercase;
    padding: 5px 10px;
}

.crusProdList .hotspotbuy .WishText {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}

.crusProdList .hotspotbuy:hover .WishText {
    background-color: #000;
    color: #fff;
}

.crusProdList .s-productthumbbox .hotspotbuy.hotspotquickbuy {
    width: calc(100% - 30px);
    text-align: center;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    bottom: 30px;
}

.crusProdList .hotspotbuy .QuickLookText {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
}

.crusProdList .hotspotbuy:hover .QuickLookText {
    background-color: rgba(51, 51, 51, 0.8);
}

.crusProdList .mobAppliedFilters {
    display: none !important;
}

.crusProdList .MobSortSelector .productFilterTitle {
    display: inline-block;
    padding: 0;
}

.crusProdList .MobSortSelector .mobAppliedFilters {
    display: inline-block !important;
    margin: 0 0 0 10px;
}

.crusProdList .MobSortSelector .productFilterTitle,
.crusProdList .MobSortSelector .mobAppliedFilters {
    line-height: 1em;
    vertical-align: middle;
}

.crusProdList #mobFilterControls li span.glyphicon {
    display: none;
}

#divPagination .disablePaginationArrow {
    display: none;
}

@media (min-width: 1022px) {
    .flanProdList .refineByLeftHeader {
        margin: 0 -5px;
        padding: 0;
    }

    .flanProdList .mobSortDesc {
        width: 100%;
    }

    .flanProdList .fixed-filters-on {
        position: fixed;
        top: 90px;
        width: 100%;
        background-color: #fff;
        margin-top: 0;
        left: 0;
        border-bottom: 1px solid #eee;
        padding: 10px 10px 0;
        z-index: 5890;
    }

    .flanProdList .fixed-filters-on #FilterContainer {
        margin: auto;
        max-width: 1420px;
    }

    .flanProdList .fixed-filters-on .refineByLeftHeader h2 .FiltersText,
    .flanProdList .fixed-filters-on .refineByLeftHeader .sortbyfilter .sortOptionsHeader {
        margin-bottom: 10px;
    }

    .flanProdList .refineByLeftHeader > .col-md-7 {
        width: 58%;
    }

    .flanProdList .refineByLeftHeader > .col-md-2 {
        width: 13%;
    }

    .flanProdList .refineByLeftHeader > .sortbyfilter {
        width: 29%;
    }

    .crusProdList #ProductContainer {
        padding-left: 25px;
    }
}

@media (max-width: 1021px) {
    .flanProdList .DesktopShow .toggleFilterInner {
        width: 100%;
    }

    .flanProdList .s-productscontainer2 > li {
        padding: 0 5px;
    }

    .flanProdList .SortQtyName {
        font-size: 1.2em;
    }

    .flanProdList .Responsive .s-left-header,
    .flanProdList .MobSortSelector,
    .flanProdList ul.productFilters > li,
    .flanProdList .productFilterTitleBox,
    .flanProdList .Responsive .FilteringApplied .productFilterTitleBox,
    .flanProdList .Responsive .productFilterList {
        border: none;
    }

    .flanProdList #FilterContainer {
        max-height: calc(100% - 146px);
    }

    .HoF-browse #FilterContainer {
        max-height: 100%;
        padding-bottom: 56px;
        width: 100% !important;
    }

    .flanProdList .Responsive .mobAppliedFilters {
        display: inline-block !important;
        height: auto;
        line-height: 1.3em;
        overflow: auto;
        letter-spacing: 0.1em;
        white-space: normal;
        vertical-align: text-bottom;
    }

    .flanProdList .Responsive .mobAppliedFilters:empty {
        border-left: none;
    }

    .flanProdList .Responsive .s-left-header h2.FiltersTitle {
        text-align: left;
        padding: 0 35px 0 5px;
    }

    .flanProdList .Responsive .mobDdClose {
        border: 0;
        height: 30px;
        width: 30px;
        padding: 0;
        right: 10px;
    }

    .flanProdList .Responsive .mobDdClose .clsFilterIcon {
        font-size: 11px;
        height: 100%;
        padding: 0;
        display: block;
        width: 100%;
        background-size: 500px 500px;
        background-position: -91px -65px;
    }

    .flanProdList .Responsive .productFilterTitle {
        text-transform: uppercase;
        font-size: 1.2em;
        letter-spacing: 0.1em;
        padding: 0;
        font-weight: bold;
        width: auto;
        position: relative;
        display: inline-block;
        margin: 0 5px 10px 0;
    }

    .flanProdList .Responsive .FilteringApplied .productFilterTitleBox {
        background: none;
    }

    .flanProdList .Responsive .productFilterList .FilterAnchor {
        padding: 10px 30px;
        letter-spacing: 0.1em;
    }

    .flanProdList a.is_disabled {
        color: #d3d3d3;
        pointer-events: none;
    }

    .flanProdList .mobAppliedFilters span.seperator::before {
        content: "";
        border-left: 1px solid #000;
    }

    .flanProdList .mobAppliedFilters span.seperator {
        display: inline-block;
        padding: 0 5px;
    }

    .flanProdList .productFilterTitleBox .glyphicon {
        display: inline-block;
    }

    .flanProdList .productFilterTitleBox.FilterClosed .glyphicon::before {
        background-position: -21px -187px;
    }

    .flanProdList .productFilterTitleBox .glyphicon::before {
        font-size: 18px;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
        background-size: 750px 750px;
        background-position: -86px -187px;
    }

    .flanProdList .Responsive .productFilterTitleBox .productFilterTitle::before,
    .flanProdList .FilterListItem a span.FilterName::before,
    .flanProdList .MobSortSelector .FilterListItem label span::before {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        bottom: -5px;
        left: 0;
        right: 100%;
        background-color: #000;
        transition: right 500ms ease-out 0s;
    }

    .flanProdList .Responsive .productFilterTitleBox:not(.FilterClosed) .productFilterTitle::before,
    .flanProdList .FilterListItem a span.SelectedFilter span.FilterName::before,
    .flanProdList .Responsive .productFilter.FilteringApplied .productFilterTitleBox .productFilterTitle::before,
    .flanProdList .MobSortSelector .mobFiltInnerWrap .productFilterTitle::before,
    .flanProdList .MobSortSelector .FilterListItem .SelectedFilter label span::before {
        right: 0;
    }

    .flanProdList .SelectedFilter {
        background: none;
        display: inline-block;
    }

    .flanProdList .SelectedFilter,
    .flanProdList .SelectableFilter {
        padding: 0;
    }

    .flanProdList .SelectedFilter::before {
        width: 20px;
        height: 20px;
        content: "";
        background-size: 750px 750px;
        background-position: -152px -187px;
    }

    .flanProdList .SelectedFilter .FilterName {
        font-weight: bold;
    }

    .flanProdList .SelectedFilter .FilterName,
    .flanProdList .SelectableFilter .FilterName {
        width: auto;
        position: relative;
        display: inline-block;
        float: left;
        margin-left: 20px;
    }

    .flanProdList .MobSortSelector h3.productFilterTitle::before {
        right: 0;
    }

    .flanProdList .MobSortSelector .FilterListItem label span {
        width: auto;
        position: relative;
        display: inline-block;
    }

    .flanProdList .SelectedFiltersContainer.mobileSelectedFilter {
        margin-top: 40px;
    }

    .flanProdList .SelectedFiltersContainer.mobileSelectedFilters ul li.selectedFilter,
    .flanProdList .SelectedFiltersWrapper.mobileSelectedFilters ul li.selectedFilter {
        display: inline-block;
        margin: 8px;
        padding: 5px;
        background-color: #ebebeb;
    }

    .flanProdList li.inlineClearAllFilters {
        padding: 8px;
        margin-top: 5px;
        font-size: 1.2em;
        cursor: pointer;
    }

    .flanProdList .SelectedFiltersContainer.mobileSelectedFilters ul li.selectedFilter a,
    .flanProdList .SelectedFiltersWrapper.mobileSelectedFilters ul li.selectedFilter a {
        color: #000;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.1em;
        cursor: pointer;
        font-size: 1.1em;
    }

    .flanProdList .SelectedFiltersContainer.mobileSelectedFilters ul li.selectedFilter span.selectedFilterLabel::before,
    .flanProdList .SelectedFiltersWrapper.mobileSelectedFilters ul li.selectedFilter span.selectedFilterLabel::before {
        width: 20px;
        height: 15px;
        content: "";
        background-size: 750px 750px;
        background-position: -155px -189px;
        display: inline-block;
        float: left;
    }

    .flanProdList #mobFilterControls {
        background: #000;
    }

    .flanProdList #mobFilterControls li.mobClearFilters {
        background: #ebebeb;
        border: 0;
    }

    .flanProdList #mobFilterControls li.mobClearFilters span {
        color: #000;
    }

    .flanProdList #mobFilterControls li span.glyphicon {
        display: none;
    }

    .flanProdList #mobControlBar {
        background: #000;
    }

    .flanProdList #mobControlBar #filterByMob span {
        letter-spacing: 0.1em;
    }

    .flanProdList #mobControlBar #filterByMob .mobFilterAppIcon::before {
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background-size: 450px 450px;
        background-position: -207px -338px;
    }
}

.mobile-read-more-less-enabled #lblCategoryCopy #read-more-less,
.mobile-read-more-less-enabled .topheadbox #read-more-less {
    margin-top: -1.5rem;
    line-height: 1.5rem;
    position: absolute;
    text-decoration: underline;
    font-size: 1.1em;
    color: #000 !important;
    font-family: inherit;
    padding: 0;
    cursor: pointer;
    background-color: transparent !important;
    border: none;
}

.mobile-read-more-less-enabled #lblCategoryCopy .textDesc:not(.read-more),
.mobile-read-more-less-enabled .topheadbox .textDesc:not(.read-more) {
    overflow: hidden;
    /*stylelint-disable*/
    display: -webkit-box;
    /*stylelint-enable*/
    -webkit-line-clamp: unset;
    -webkit-box-orient: vertical;
}

#lblCategoryCopy .textDesc {
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 1021px) {
    #lblCategoryCopy .textDesc {
        text-align: left;
    }
}
